import React from 'react';

const BackIcon = ({ width = "20px", height = "20px", color = "#696969" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
      <path d="M15.8334 10H4.16675" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.0001 15.8334L4.16675 10.0001L10.0001 4.16675" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default BackIcon;
