import React, { useState,useEffect } from 'react';

const ImageComponent = ({ src, height, width,onLoad }) => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoaded = () => {
    if (!loaded){
      setLoaded(true);
      onLoad();
    }
  };


  return (
    <div style={{backgroundColor:"white", position: 'relative', height: `${height}px`, width: `${width}px`, overflow: 'hidden' }}>
    <img
      src={src}
      alt="imagen"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        objectFit:'cover',
        opacity: loaded ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
      }}
      onLoad={handleImageLoaded}
      loading="lazy"
    />
  </div>
  );
};

export default ImageComponent;