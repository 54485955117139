import React from 'react';

const DescuentoIcon = ({ width = "24px", height = "24px", color = "#C7506C" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
  <path d="M22.5217 8.65224C22.6486 8.65224 22.7702 8.60185 22.8599 8.51216C22.9496 8.42247 23 8.30082 23 8.17398V5.78267C23 5.2753 22.7984 4.78871 22.4397 4.42995C22.0809 4.07118 21.5943 3.86963 21.087 3.86963H2.91304C2.40567 3.86963 1.91908 4.07118 1.56032 4.42995C1.20155 4.78871 1 5.2753 1 5.78267V8.17398C1 8.30082 1.05039 8.42247 1.14008 8.51216C1.22977 8.60185 1.35142 8.65224 1.47826 8.65224C2.36616 8.65224 3.21769 9.00495 3.84553 9.63279C4.47337 10.2606 4.82609 11.1122 4.82609 12.0001C4.82609 12.888 4.47337 13.7395 3.84553 14.3673C3.21769 14.9952 2.36616 15.3479 1.47826 15.3479C1.35142 15.3479 1.22977 15.3983 1.14008 15.488C1.05039 15.5777 1 15.6993 1 15.8262V18.2175C1 18.7248 1.20155 19.2114 1.56032 19.5702C1.91908 19.9289 2.40567 20.1305 2.91304 20.1305H21.087C21.5943 20.1305 22.0809 19.9289 22.4397 19.5702C22.7984 19.2114 23 18.7248 23 18.2175V15.8262C23 15.6993 22.9496 15.5777 22.8599 15.488C22.7702 15.3983 22.6486 15.3479 22.5217 15.3479C21.6338 15.3479 20.7823 14.9952 20.1545 14.3673C19.5266 13.7395 19.1739 12.888 19.1739 12.0001C19.1739 11.1122 19.5266 10.2606 20.1545 9.63279C20.7823 9.00495 21.6338 8.65224 22.5217 8.65224Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.5585 8.83508C11.595 8.74823 11.6563 8.67409 11.7347 8.62195C11.8132 8.56981 11.9053 8.54199 11.9995 8.54199C12.0937 8.54199 12.1858 8.56981 12.2642 8.62195C12.3427 8.67409 12.404 8.74823 12.4404 8.83508L13.2344 10.7328H14.7744C14.8733 10.7328 14.9697 10.7635 15.0505 10.8207C15.1312 10.8778 15.1922 10.9585 15.2252 11.0518C15.2581 11.1451 15.2613 11.2462 15.2344 11.3414C15.2074 11.4366 15.1516 11.521 15.0747 11.5832L13.6504 12.731L14.3382 14.5656C14.3741 14.6615 14.3784 14.7664 14.3503 14.8649C14.3221 14.9634 14.2631 15.0502 14.182 15.1126C14.1008 15.1751 14.0017 15.2098 13.8993 15.2117C13.7969 15.2136 13.6966 15.1826 13.6131 15.1232L12.0004 13.9754L10.3877 15.1232C10.3044 15.1828 10.2041 15.214 10.1016 15.2123C9.99917 15.2106 9.89998 15.176 9.81867 15.1137C9.73736 15.0513 9.67822 14.9645 9.64997 14.866C9.62171 14.7675 9.62584 14.6625 9.66175 14.5666L10.3495 12.7319L8.92522 11.5841C8.84828 11.522 8.79248 11.4375 8.76553 11.3424C8.73858 11.2472 8.7418 11.146 8.77474 11.0528C8.80768 10.9595 8.86872 10.8787 8.94946 10.8216C9.0302 10.7645 9.12667 10.7338 9.22557 10.7338H10.757L11.5585 8.83508Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.82605 6.02197C4.88947 6.02197 4.9503 6.04717 4.99514 6.09201C5.03999 6.13686 5.06518 6.19768 5.06518 6.2611" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.58691 6.2611C4.58691 6.19768 4.61211 6.13686 4.65695 6.09201C4.7018 6.04717 4.76262 6.02197 4.82604 6.02197" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.82604 6.49987C4.76262 6.49987 4.7018 6.47468 4.65695 6.42983C4.61211 6.38499 4.58691 6.32416 4.58691 6.26074" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.06518 6.26074C5.06518 6.32416 5.03999 6.38499 4.99514 6.42983C4.9503 6.47468 4.88947 6.49987 4.82605 6.49987" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.69568 6.02197C7.7591 6.02197 7.81992 6.04717 7.86477 6.09201C7.90962 6.13686 7.93481 6.19768 7.93481 6.2611" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.45654 6.2611C7.45654 6.19768 7.48174 6.13686 7.52658 6.09201C7.57143 6.04717 7.63225 6.02197 7.69567 6.02197" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.69567 6.49987C7.63225 6.49987 7.57143 6.47468 7.52658 6.42983C7.48174 6.38499 7.45654 6.32416 7.45654 6.26074" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.93481 6.26074C7.93481 6.32416 7.90962 6.38499 7.86477 6.42983C7.81992 6.47468 7.7591 6.49987 7.69568 6.49987" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.5652 6.02197C10.6286 6.02197 10.6894 6.04717 10.7343 6.09201C10.7791 6.13686 10.8043 6.19768 10.8043 6.2611" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.326 6.2611C10.326 6.19768 10.3512 6.13686 10.3961 6.09201C10.4409 6.04717 10.5018 6.02197 10.5652 6.02197" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.5652 6.49987C10.5018 6.49987 10.4409 6.47468 10.3961 6.42983C10.3512 6.38499 10.326 6.32416 10.326 6.26074" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.8043 6.26074C10.8043 6.32416 10.7791 6.38499 10.7343 6.42983C10.6894 6.47468 10.6286 6.49987 10.5652 6.49987" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.4348 6.02197C13.4982 6.02197 13.5591 6.04717 13.6039 6.09201C13.6488 6.13686 13.6739 6.19768 13.6739 6.2611" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.1957 6.2611C13.1957 6.19768 13.2209 6.13686 13.2657 6.09201C13.3106 6.04717 13.3714 6.02197 13.4348 6.02197" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.4348 6.49987C13.3714 6.49987 13.3106 6.47468 13.2657 6.42983C13.2209 6.38499 13.1957 6.32416 13.1957 6.26074" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.6739 6.26074C13.6739 6.32416 13.6488 6.38499 13.6039 6.42983C13.5591 6.47468 13.4982 6.49987 13.4348 6.49987" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.3043 6.02197C16.3677 6.02197 16.4286 6.04717 16.4734 6.09201C16.5183 6.13686 16.5435 6.19768 16.5435 6.2611" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.0652 6.2611C16.0652 6.19768 16.0904 6.13686 16.1352 6.09201C16.1801 6.04717 16.2409 6.02197 16.3043 6.02197" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.3043 6.49987C16.2409 6.49987 16.1801 6.47468 16.1352 6.42983C16.0904 6.38499 16.0652 6.32416 16.0652 6.26074" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.5435 6.26074C16.5435 6.32416 16.5183 6.38499 16.4734 6.42983C16.4286 6.47468 16.3677 6.49987 16.3043 6.49987" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19.174 6.02197C19.2374 6.02197 19.2982 6.04717 19.343 6.09201C19.3879 6.13686 19.4131 6.19768 19.4131 6.2611" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.9348 6.2611C18.9348 6.19768 18.96 6.13686 19.0049 6.09201C19.0497 6.04717 19.1105 6.02197 19.1739 6.02197" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19.1739 6.49987C19.1105 6.49987 19.0497 6.47468 19.0049 6.42983C18.96 6.38499 18.9348 6.32416 18.9348 6.26074" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19.4131 6.26074C19.4131 6.32416 19.3879 6.38499 19.343 6.42983C19.2982 6.47468 19.2374 6.49987 19.174 6.49987" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.82605 17.5C4.88947 17.5 4.9503 17.5252 4.99514 17.57C5.03999 17.6149 5.06518 17.6757 5.06518 17.7391" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.58691 17.7391C4.58691 17.6757 4.61211 17.6149 4.65695 17.57C4.7018 17.5252 4.76262 17.5 4.82604 17.5" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.82604 17.9784C4.76262 17.9784 4.7018 17.9532 4.65695 17.9083C4.61211 17.8635 4.58691 17.8027 4.58691 17.7393" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.06518 17.7393C5.06518 17.8027 5.03999 17.8635 4.99514 17.9083C4.9503 17.9532 4.88947 17.9784 4.82605 17.9784" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.69568 17.5C7.7591 17.5 7.81992 17.5252 7.86477 17.57C7.90962 17.6149 7.93481 17.6757 7.93481 17.7391" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.45654 17.7391C7.45654 17.6757 7.48174 17.6149 7.52658 17.57C7.57143 17.5252 7.63225 17.5 7.69567 17.5" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.69567 17.9784C7.63225 17.9784 7.57143 17.9532 7.52658 17.9083C7.48174 17.8635 7.45654 17.8027 7.45654 17.7393" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.93481 17.7393C7.93481 17.8027 7.90962 17.8635 7.86477 17.9083C7.81992 17.9532 7.7591 17.9784 7.69568 17.9784" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.5652 17.5C10.6286 17.5 10.6894 17.5252 10.7343 17.57C10.7791 17.6149 10.8043 17.6757 10.8043 17.7391" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.326 17.7391C10.326 17.6757 10.3512 17.6149 10.3961 17.57C10.4409 17.5252 10.5018 17.5 10.5652 17.5" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.5652 17.9784C10.5018 17.9784 10.4409 17.9532 10.3961 17.9083C10.3512 17.8635 10.326 17.8027 10.326 17.7393" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.8043 17.7393C10.8043 17.8027 10.7791 17.8635 10.7343 17.9083C10.6894 17.9532 10.6286 17.9784 10.5652 17.9784" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.4348 17.5C13.4982 17.5 13.5591 17.5252 13.6039 17.57C13.6488 17.6149 13.6739 17.6757 13.6739 17.7391" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.1957 17.7391C13.1957 17.6757 13.2209 17.6149 13.2657 17.57C13.3106 17.5252 13.3714 17.5 13.4348 17.5" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.4348 17.9784C13.3714 17.9784 13.3106 17.9532 13.2657 17.9083C13.2209 17.8635 13.1957 17.8027 13.1957 17.7393" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.6739 17.7393C13.6739 17.8027 13.6488 17.8635 13.6039 17.9083C13.5591 17.9532 13.4982 17.9784 13.4348 17.9784" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.3043 17.5C16.3677 17.5 16.4286 17.5252 16.4734 17.57C16.5183 17.6149 16.5435 17.6757 16.5435 17.7391" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.0652 17.7391C16.0652 17.6757 16.0904 17.6149 16.1352 17.57C16.1801 17.5252 16.2409 17.5 16.3043 17.5" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.3043 17.9784C16.2409 17.9784 16.1801 17.9532 16.1352 17.9083C16.0904 17.8635 16.0652 17.8027 16.0652 17.7393" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.5435 17.7393C16.5435 17.8027 16.5183 17.8635 16.4734 17.9083C16.4286 17.9532 16.3677 17.9784 16.3043 17.9784" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19.174 17.5C19.2374 17.5 19.2982 17.5252 19.343 17.57C19.3879 17.6149 19.4131 17.6757 19.4131 17.7391" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.9348 17.7391C18.9348 17.6757 18.96 17.6149 19.0049 17.57C19.0497 17.5252 19.1105 17.5 19.1739 17.5" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19.1739 17.9784C19.1105 17.9784 19.0497 17.9532 19.0049 17.9083C18.96 17.8635 18.9348 17.8027 18.9348 17.7393" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19.4131 17.7393C19.4131 17.8027 19.3879 17.8635 19.343 17.9083C19.2982 17.9532 19.2374 17.9784 19.174 17.9784" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
</svg> 
);

export default DescuentoIcon;
