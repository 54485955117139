import React from 'react';

function PileIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "32px"}
      height={height || "32px"}
      viewBox="0 0 32 32"
      fill="none"
      className="pile-icon"
    >
      <path
        d="M25.5775 10.412H6.91081C5.43805 10.412 4.24414 11.6059 4.24414 13.0787V27.7453C4.24414 29.2181 5.43805 30.412 6.91081 30.412H25.5775C27.0502 30.412 28.2441 29.2181 28.2441 27.7453V13.0787C28.2441 11.6059 27.0502 10.412 25.5775 10.412Z"
        stroke={color || "#696969"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.91089 6.41199L25.5776 6.41199"
        stroke={color || "#696969"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.57739 2.41199H22.9107"
        stroke={color || "#696969"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PileIcon;