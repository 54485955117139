import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MeAPI, LogoutAPI, peekNotificacionsAPI } from "../../API";

//Icons
import MenuIcon from "../../assets/SVG/MenuIcon";
import BellIcon from "../../assets/SVG/BellIcon";
import CLogoIcon from "../../assets/SVG/CLogoIcon";
import StatusIcon from "../../assets/SVG/StatusIcon";

function NavBar(props) {
  const location = useLocation(); // Get the current location
  const [currentHref, setCurrentHref] = React.useState(location.pathname);
  const noShowPaths = ["/login", "/register", "/activate", "/resetpassword"];
  const [isOpen, setIsOpen] = useState(false);
  const setShow = props.setShow;
  const [myID, setMyID] = useState(props.username);
  const [isBio,setIsBio] = useState(props.is_bio);
  let navigate = useNavigate();
  const modalRef = useRef(null);
  const [notifications, setNotification] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const me = JSON.parse(localStorage.getItem("me"));
      if (me) {
        setMyID(me.username);
        setIsBio(me.is_bio);
      } else {
        clearInterval(intervalId); // Detiene el intervalo cuando el valor es nulo
      }
    }, 1000); // Cambiado a 1000 para que se ejecute cada segundo
  
    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalId);
    
  }, []);
  

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const me = JSON.parse(localStorage.getItem("me"));
      if (me) {
        setMyID(me.username);
        setIsBio(me.is_bio);
        peekNotificacionsAPI().then((res) =>
          setNotification(res.unread)
        );
      }
    }, 60000);

    return () => clearInterval(intervalId); //This is important
  }, []);

  const Modal = () => {
    const links = [
      { url: isBio ?  "../../@" + myID :  "../../@" + myID + "/edit" , name: "Mi cuenta" },
      { url: "../../mywishlist/", name: "Wishlist" },
      { url: "../../benefits/", name: "Beneficios" },
      { url: "../../help/", name: "Ayuda" },
    ];

    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <div className="ModalMenu" onClick={closeModal}>
        <div className="ModalBox">
          <div className="BottomModalRectangle Modal" onClick={closeModal} />
          <div>
            {links.map((link) => (
              <div
                className="ModalLink"
                onClick={() => {
                  navigate(link.url);
                  setIsOpen(false);
                }}
              >
                {link.name}
              </div>
            ))}
          </div>
          <div className="Divider" />
          <div
            className="MainModalMenu"
            onClick={() => {
              LogoutAPI();
              navigate("../../login");
            }}
          >
            Cerrar Sesión
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setCurrentHref(location.pathname); // Update currentHref when the location changes
  }, [location]);

  if (noShowPaths.includes(currentHref)) {
    return <div />;
  } else {
    return (
      <div className="MobileNavBarLayout">
        <div className="InnerNav">
          <div
            onClick={() => {
              myID ? setIsOpen(true) : setShow(true);
            }}
          >
            <MenuIcon />
          </div>
          <div
            onClick={() => {
              setIsOpen(false);
              navigate("../../feed");
            }}
          >
            <CLogoIcon />
          </div>
          <div
            className="NotificationIconAndBagde"
            onClick={() => {
              if (myID) {
                setIsOpen(false);
                navigate("../../notifications");
              } else {
                setShow(true);
              }
            }}
          >
            <BellIcon />
            {notifications > 0  && (
              <div className='BadgedNumber'> {notifications} </div>
            )}
          </div>
        </div>
        {isOpen && <Modal />}
      </div>
    );
  }
}

export default NavBar;
