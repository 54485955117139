import React from 'react';

function CLogoIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "32px"}
      height={height || "40px"}
      viewBox="0 0 32 40"
      fill="none"
    >
      <g clipPath="url(#clip0_319_1853)">
        <path
          d="M30.1913 12.5478C29.8178 11.4273 29.3642 10.2446 28.8396 9.00846C28.3149 7.77236 27.6301 6.63408 26.8031 5.59361C25.9672 4.56204 24.9356 3.70833 23.6995 3.05026C22.4545 2.39219 20.9249 2.05426 19.093 2.05426C16.6119 2.05426 14.6644 2.5078 13.2415 3.42376C11.8187 4.33972 10.7337 5.6114 10.0045 7.23879C9.27531 8.86618 8.81288 10.8137 8.60834 13.0636C8.40381 15.3224 8.30599 17.7946 8.30599 20.4713C8.30599 24.0018 8.51052 26.9186 8.91959 29.2308C9.32866 31.534 9.96006 33.3749 10.8316 34.7266C11.6942 36.0872 12.8147 37.0387 14.193 37.5812C15.5714 38.1236 17.2077 38.3993 19.1108 38.3993C20.6404 38.3993 22.0187 38.097 23.2637 37.4833C24.4998 36.8697 25.5937 36.0694 26.5452 35.0645C27.4967 34.0596 28.2971 32.9213 28.9374 31.6319C29.5777 30.3424 30.0579 28.9996 30.3602 27.6123H31.1784V38.4082H30.3602C30.1913 37.9991 29.9867 37.7235 29.7466 37.5723C29.5065 37.4211 29.1508 37.3411 28.6795 37.3411C28.2704 37.3411 27.7457 37.43 27.1055 37.6167C26.4563 37.8035 25.7182 38.008 24.8911 38.2303C24.0552 38.4527 23.1481 38.6572 22.1699 38.844C21.1917 39.0307 20.1513 39.1196 19.0663 39.1196C16.5852 39.1196 14.193 38.6394 11.8898 37.6701C9.57766 36.7008 7.5501 35.3757 5.8071 33.695C4.05521 32.0142 2.65903 30.04 1.60968 27.7635C0.560322 25.4869 0.0356445 23.0325 0.0356445 20.3824C0.0356445 17.5011 0.622572 14.8866 1.78753 12.5389C2.96139 10.2001 4.46428 8.19922 6.2962 6.53625C8.12813 4.87329 10.1735 3.59272 12.4323 2.69455C14.691 1.79637 16.9054 1.34283 19.0752 1.34283C20.1601 1.34283 21.1739 1.44066 22.1077 1.64519C23.0414 1.84973 23.904 2.06315 24.7044 2.28548C25.5047 2.5078 26.2251 2.72122 26.8653 2.92576C27.5056 3.1303 28.0481 3.22812 28.4927 3.22812C28.9641 3.22812 29.3198 3.16587 29.5599 3.05026C29.8 2.93465 29.9689 2.6323 30.0668 2.16098H30.8849V12.5478H30.2268H30.1913Z"
          fill={color || "#696969"}
        />
        <path
          d="M25.3739 -0.017749L9.60303 39.7338L10.2808 40.0027L26.0517 0.251165L25.3739 -0.017749Z"
          fill={color || "#696969"}
        />
      </g>
      <defs>
        <clipPath id="clip0_319_1853">
          <rect width="31.1605" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CLogoIcon;
