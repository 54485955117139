import React from 'react';

const FourSquareIcon = ({ height, width, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "28px"}
      height={height || "28px"}
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        fill={color ||  "#E1E1E1" }
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.68337 0H7.31663C9.90318 0 12 2.09682 12 4.68337V7.31663C12 9.90318 9.90318 12 7.31663 12H4.68337C2.09682 12 0 9.90318 0 7.31663V4.68337C0 2.09682 2.09682 0 4.68337 0ZM20.6834 0H23.3166C25.9032 0 28 2.09682 28 4.68337V7.31663C28 9.90318 25.9032 12 23.3166 12H20.6834C18.0968 12 16 9.90318 16 7.31663V4.68337C16 2.09682 18.0968 0 20.6834 0ZM7.31663 16H4.68337C2.09682 16 0 18.0968 0 20.6834V23.3166C0 25.9032 2.09682 28 4.68337 28H7.31663C9.90318 28 12 25.9032 12 23.3166V20.6834C12 18.0968 9.90318 16 7.31663 16ZM20.6834 16H23.3166C25.9032 16 28 18.0968 28 20.6834V23.3166C28 25.9032 25.9032 28 23.3166 28H20.6834C18.0968 28 16 25.9032 16 23.3166V20.6834C16 18.0968 18.0968 16 20.6834 16Z"
      />
    </svg>
  );
};

export default FourSquareIcon;
