import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNotificationsAPI,fetchMoreAPI,markNotificationsAsReadAPI } from "../../API";
import InfiniteScroll from "react-infinite-scroll-component";
import BackIcon from "../../assets/SVG/BackIcon";
import BookmarkIcon from "../../assets/SVG/BookmarkIcon";
import UserIcon from "../../assets/SVG/UserIcon";
import RegaloIcon from "../../assets/SVG/RegaloIcon";
import BadgdeIcon from "../../assets/SVG/BadgeIcon";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";
import CBCLogo from "../../assets/SVG/CBCLogo";



export default function Notifications() {
  let navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [next, setNext] = useState();
  var moment = require('moment'); 
  
  useEffect(() => {
    getNotificationsAPI().then((res) => {
      setNotifications(res.results);
      setNext(res.next);
      markNotificationsAsReadAPI();
    }
    );
  }, []);


  function extraerUser(str) {
    const partes = str.split(" ");
    console.log(partes)
    const user = partes[0].slice(1);
    return user
  }

  const fetchMore = () => {
    fetchMoreAPI(next).then((res) => {
      setNotifications((notifications) => {
        if (res?.results) {
          return [...notifications, ...res.results];
        } else {
          return notifications;
        }
      });
      setNext(res.next);
    });
  };

  function obtenerFechaRelativa(fecha, ahora) {
    const fechaNotificacion = moment(fecha);
    const diferenciaMinutos = ahora.diff(fechaNotificacion, 'minutes');
    const diferenciaHoras = ahora.diff(fechaNotificacion, 'hours');
    const diferenciaDias = ahora.diff(fechaNotificacion, 'days');

    // Si la diferencia es menor a una hora, mostramos en minutos
    if (diferenciaMinutos < 60) {
        return `Hace ${diferenciaMinutos} minutos`;
    }
    // Si la diferencia es menor a un día, mostramos en horas
    else if (diferenciaHoras < 24) {
        return `Hace ${diferenciaHoras} horas`;
    }
    // Si la diferencia es menor a dos días, mostramos "ayer"
    else if (diferenciaDias === 1) {
        return "Ayer";
    }
    // Si la diferencia es menor a diez días, mostramos en días
    else if (diferenciaDias < 10) {
        return `Hace ${diferenciaDias} días`;
    }
    // De lo contrario, mostramos la fecha en formato "5 de marzo"
    else {
        return fechaNotificacion.format('D [de] MMMM');
    }
}


function parseNotification(notification) {
  // Parsear la fecha actual
  const ahora = moment();
  const { message, content_type, created_at } = notification;
  const type = content_type.model;
  let parsedNotification;
  let link;
  console.log(notification)

  if (type === "post") {
      if (message.includes("le ha gustado tu publicación")) {
          // Si es una notificación de "me gusta en publicación"
          const cut_message = message.split("le ha gustado tu publicación")[0] + "le ha gustado tu publicación" ;
          parsedNotification = {
              fecha: obtenerFechaRelativa(created_at, ahora), // No se necesita mostrar la fecha para esta notificación
              tipo: "Me gusta en publicación",
              mensaje: cut_message,
              icon: <BookmarkIcon height="20px" width="20px" />,
              link: "" //`../@${message.split(" by ")[1]}/post/${notification.object_id}`
          };
      } else {
          // Si es una notificación de "Recomendaciones"
          parsedNotification = {
              fecha: obtenerFechaRelativa(created_at, ahora),
              tipo: "Recomendaciones",
              mensaje: "Se han añadido nuevas recomendaciones en un post guardado",
              icon: <CBCLogo color="#696969" height="20px" width="20px" />,
              link :"" //`../@${message.split(" by ")[1]}/post/${notification.object_id}`
          };
          
      }
  } else if (type === "benefit") {
      // Si es una notificación de "Beneficio"
      parsedNotification = {
          fecha: obtenerFechaRelativa(created_at, ahora),
          tipo: "Beneficio",
          mensaje: "¡Tienes un nuevo beneficio!",
          icon: <RegaloIcon color="#696969" height="20px" width="20px" />,
          link: "../benefits"
      };
  } else if (type === "follow") {
      // Si es una notificación de "Nuevo Seguidor!"
      parsedNotification = {
          fecha:obtenerFechaRelativa(created_at, ahora), // No se necesita mostrar la fecha para esta notificación
          tipo: "Nuevo Seguidor!",
          mensaje: message,
          icon: <UserIcon height="20px" width="20px" />,
          link : ""
      };
     
  }

  return parsedNotification;
}


  return (

    <div className='ProfileLayout' style={{}}>
        <ScrollToTopOnMount />
        <div className="HeaderSpacer" style={{ height: "56px", width: "30px", }} />
        <div className='ProfileBox'>
            <div className='ProfileHeaderBar'>
                <button className='FullPostHeaderButton'  onClick={() => navigate(-1)}>
                    <BackIcon  />
                </button>
                <div className="ProfileEditTitle"> Mis Notificaciones </div>
                <button className='FullPostHeaderButton'>
                    <div style={{width:"20px", height:"24px"}} />
                </button>
            </div>
              <InfiniteScroll
                dataLength={notifications ? notifications.length : 5}
                next={fetchMore}
                hasMore={true}
                //loader={<h4>Loading...</h4>}
              >
              {notifications.length>0  && 
                notifications.map((notification) => {
                const parsedNotification = parseNotification(notification)
                return (
                  <button className="NotificationButton" onClick={() => navigate(parsedNotification.link)}>
                  <div className="NotificationBox"> 
                  {!notification.is_read && 
                    <div className="NotificationBadge">
                      <BadgdeIcon />
                    </div>
                  }
                  <div className="NotificationImage">
                    {parsedNotification?.icon}
                  </div>
                  <div className="NotificationInfo">
                    <div className="NotificationInfoTop">
                        <div className="NotificationTitle">
                          {parsedNotification?.tipo}
                        </div>
                        <div className="NotificationDate">
                            {parsedNotification?.fecha}
                        </div>
                    </div>
                    <div className="NotificationInfoBottom">
                          {parsedNotification?.mensaje}
                    </div>
                  </div>
                  </div>
                  <div className="NotificationBadge"/>
                  </button>
                );
              })}
              
               </InfiniteScroll> 
        </div>  
</div>
  )};
   