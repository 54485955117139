import React, {useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

//Icons Imports
import CameraIcon from '../../assets/SVG/CameraIcon';
import BottomBarShape from '../../assets/SVG/BottomBarShape';
import BookmarkIcon from '../../assets/SVG/BookmarkIcon';
import UserIcon from '../../assets/SVG/UserIcon';
import SearchIcon from '../../assets/SVG/SearchIcon';
import PileIcon from '../../assets/SVG/PileIcon';

export default function Bottombar(props) {
  const location = useLocation(); // Get the current location
  const [currentHref,setCurrentHref] = React.useState(location.pathname);
  const [myID, setMyID] = React.useState();
  const [isBio, setIsBio] = React.useState();
  const navigate = useNavigate();
  const noShowPaths = ["/login", "/register", "/activate", "/resetpassword", "/new","/edit","/notifications"];
  const setShow = props.setShow;
  const setShowCreator = props.setShowCreator


  //In case the bottom bar is too short, lets add one block on each side.
  const [diferencia, setDiferencia] = React.useState(null);
  useEffect(() => {
    const calcularDiferencia = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth > 1670) {
        const diff = (screenWidth - 1670) / 2 +1;
        setDiferencia(diff);
      } else {
        setDiferencia(null);
      }
    };

    calcularDiferencia();

    // Agregar un event listener para actualizar la diferencia cuando cambie el tamaño de la pantalla
    window.addEventListener('resize', calcularDiferencia);

    // Limpieza del event listener cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', calcularDiferencia);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const me = JSON.parse(localStorage.getItem("me"));
      if (me) {
        setMyID(me.username);
        setIsBio(me.is_bio);
      } else {
        clearInterval(intervalId); // Detiene el intervalo cuando el valor es nulo
      }
    }, 1000); // Cambiado a 1000 para que se ejecute cada segundo
  
    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, []);
  

  useEffect(() => {
    if (location.pathname.includes("/edit")){
      setCurrentHref("/edit")
    
      
    }
    else if (location.pathname.includes("/post/")){
      setCurrentHref("/post/")
      
    }
    else{
      setCurrentHref(location.pathname); // Update currentHref when the location changes
    }
  }, [location]);
  

  if  (noShowPaths.includes(currentHref)){
    return( <div /> )
  }

  return (

      <div className='BottomBar'>
        <div className='FAB' onClick={() => { myID  ? (isBio ? navigate("/new") : setShowCreator(true)) : setShow(true)}}>
            <CameraIcon/>
        </div>
        <div className='Shape'>
          <div style={{width:diferencia+"px",height:"71px",backgroundColor:'white'}} />
          <BottomBarShape width={diferencia > 0 ? "1670px" : "100vw"}/>
          <div style={{width:diferencia+"px",height:"71px",backgroundColor:'white'}} />
        </div>
        <div className='menuWrap'>
          <div className='ButtonGroup'>
            <div className='MenuItem' onClick={() => navigate("../../feed")} >
              <PileIcon color={currentHref.includes("feed") ? "#B6AA98" : "#696969" } />
            </div>
            <div className='MenuItem' onClick={() => navigate("../../searchbar")} >
                <SearchIcon color={currentHref.includes("search") ? "#B6AA98" : "#696969" }  />
            </div>

          </div>
          <div  className='ButtonGroup'>
          <div className='MenuItem'  onClick={() => {myID ?  navigate("../../myWishlist") : setShow(true)} }>
            <BookmarkIcon color={currentHref.includes("myWishlist") ? "#B6AA98" : "#696969" } />
          </div>
          <div className='MenuItem'  onClick={() => {myID ?  (isBio ? navigate("../../@" + myID) : navigate("../../@"+myID+"/edit") ) : setShow(true)} }>
            <UserIcon color={currentHref.includes("/@"+ myID ) ? "#B6AA98" : "#696969" } />
          </div>
          </div>
        </div>

      </div>
    )

  }
