import React, { useState, useRef } from 'react';

const LongPressButton = ({ onLongPress, children }) => {
  const [isLongPressing, setIsLongPressing] = useState(false);
  const timerRef = useRef(null);

  const handleTouchStart = () => {
    timerRef.current = setTimeout(() => {
      setIsLongPressing(true);
      if (onLongPress) {
        onLongPress();
      }
    }, 500); // Ajusta el tiempo en milisegundos para definir cuánto tiempo constituye un long press
  };

  const handleTouchEnd = () => {
    clearTimeout(timerRef.current);
    if (isLongPressing) {
      setIsLongPressing(false);
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault(); // Evita que se abra el menú de botón derecho
  };

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
      onContextMenu={handleContextMenu} // Evita que se abra el menú de botón derecho en dispositivos táctiles
    >
      {children}
    </div>
  );
};

export default LongPressButton;
