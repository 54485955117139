import React from "react";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";
import { useNavigate} from 'react-router-dom';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';



import BackIcon from "../../assets/SVG/BackIcon";


function FAQ() {
    const navigate = useNavigate();

    return ( 
    <div className='ProfileLayout' >
        <ScrollToTopOnMount />
        <div className="HeaderSpacer" style={{ height: "56px", width: "30px", }} />
        <div className='ProfileBox' >
            <div className='ProfileHeaderBar'>
            <button className='FullPostHeaderButton' onClick={() => navigate(-1)}>
                <BackIcon />
            </button>
            <div className="ProfileEditTitle"> Preguntas Frecuentes </div>
            <div className='FullPostHeaderButton'>
                <div style={{width: "20px", height:"20px"}} />
            </div>
            </div>
            <div className="ProfileEditBody">
            

<div className="TitleHelp"> ¡Bienvenido a la sección de ayuda de Cranberry Chic! </div>
<div className="TextHelp"> Aquí encontrarás respuestas a algunas preguntas frecuentes sobre nuestro sitio web. </div>
<div  className="SubTitleHelp">
            <ol>
                <li><Link to="Q1" activeClass="active" className="Q1" spy={true} smooth={true} duration={500} offset={-60}>¿Qué es Cranberry Chic?</Link></li>
                <li><Link to="Q2" activeClass="active" className="Q1" spy={true} smooth={true} duration={500} offset={-60}>¿Cómo puedo crear una cuenta en Cranberry Chic?</Link></li>
                <li><Link to="Q3" activeClass="active" className="Q1" spy={true} smooth={true} duration={500} offset={-60}>¿Cómo puedo publicar contenido en Cranberry Chic?</Link></li>
                <li><Link to="Q4" activeClass="active" className="Q1" spy={true} smooth={true} duration={500} offset={-60}>¿Cómo puedo recuperar mi contraseña si la olvidé?</Link></li>
                <li><Link to="Q5" activeClass="active" className="Q1" spy={true} smooth={true} duration={500} offset={-60}>¿De dónde provienen los productos recomendados en Cranberry?</Link></li>
                <li><Link to="Q6" activeClass="active" className="Q1" spy={true} smooth={true} duration={500} offset={-60}>¿Cómo puedo hacer que mi marca sea parte de Cranberry?</Link></li>
            </ol>
</div>


<Element name="Q1" className="element" >
<div className="SubTitleHelp">1. ¿Qué es Cranberry Chic?</div>
<div  className="TextHelp">Es una comunidad en línea donde las mujeres comparten moda y lifestyle. Nuestra misión es mantener altos estándares de calidad en nuestra plataforma, evitando spam, bots y otros tipos de contenido no deseado. Somos una comunidad exclusiva para aquellos que comparten nuestra pasión por la moda y el estilo de vida.</div>
</Element>
<Element name="Q2" className="element" >
<div className="SubTitleHelp">2. ¿Cómo puedo crear una cuenta en Cranberry Chic?</div>
<div  className="TextHelp">Crear una cuenta es rápido y fácil. Sigue estos pasos para registrarte:
            <ol>
                <li>Entra a la página de inicio de Cranberry Chic.</li>
                <li>Haz click en el botón "Registrarse" en la esquina superior derecha.</li>
                <li>Completa el formulario de registro con tu nombre, dirección de correo electrónico, whatsapp o número de teléfono y contraseña.</li>
                <li>Acepta los términos y condiciones y haz click en "Registrarse".</li>
            </ol>
            ¡Listo! Tu cuenta de Cranberry ha sido creada. Ahora puedes empezar a explorar, buscar inspiración y compartir contenido.
</div>
</Element>
<Element name="Q3" className="element" >
<div className="SubTitleHelp">3. ¿Cómo puedo publicar contenido en Cranberry Chic?</div>
<div  className="TextHelp">Para convertirte en generadora de contenido debes seguir estos pasos:</div>
<div  className="TextHelp">
Primero, asegúrate de tener una cuenta en Cranberry Chic.
Una vez que hayas iniciado sesión, haz click en el botón central de la cámara de fotos para iniciar el proceso de registro.
Sigue las instrucciones proporcionadas para completar el registro que llegará a manos de nuestro equipo. Próximamente, queremos ir descubriendo nuevos perfiles y voces que aporten contenido en Cranberry Chic para que así más personas puedan compartir publicaciones con la comunidad, acceder a benefios especiales, invitaciones a eventos y mucho más.
</div>
</Element>
<Element name="Q4" className="element" >
<div className="SubTitleHelp">4. ¿Cómo puedo publicar contenido en Cranberry Chic?</div>
<div  className="TextHelp">Si olvidaste tu contraseña, no te preocupes. Sigue estos pasos para restablecerla:

<ol>
                <li>Entra a la página de inicio de sesión de Cranberry.</li>
                <li>Haz click en: "¿Olvidaste tu contraseña?", debajo del formulario de inicio de sesión.</li>
                <li>Ingresa tu dirección de correo electrónico y haz click en "Enviar".</li>
                <li>Recibirás un correo electrónico con un enlace para restablecer tu contraseña.</li>
                <li>Sigue las instrucciones del correo electrónico para crear una nueva contraseña.</li>
</ol>

</div>
<div  className="TextHelp">
Si no puedes acceder a tu correo, por favor contáctanos en <a href="mailto:ayuda@cranberrychic.com" className="Q1">ayuda@cranberrychic.com</a>. 
También puedes entrar en configuraciones de tu cuenta para cambiar tu contraseña.
</div>
</Element>


<Element name="Q5" className="element" >
<div className="SubTitleHelp">5. ¿De dónde provienen los productos recomendados en Cranberry?</div>
<div  className="TextHelp">
Los productos recomendados son una selección y curatoría de nuestro equipo experto donde la calidad y el diseño de estos, juegan rol fundamental en la selección. No se eligen productos basados en influencias de personas que suben contenido. Trabajamos con tiendas cuidadosamente seleccionadas para garantizar que ofrecemos lo mejor a nuestra comunidad.
</div>
</Element>

<Element name="Q6" className="element" >
<div className="SubTitleHelp">6. ¿Cómo puedo hacer que mi marca sea parte de Cranberry?</div>
<div  className="TextHelp">
Si estás interesada en que tu marca forme parte de Cranberry Chic, por favor contáctanos a [correo electrónico de Cranberry]. Estaremos encantados de discutir las oportunidades de colaboración contigo.
</div>
</Element>

<div className="TextHelp">¡Esperamos que esta sección de ayuda haya sido útil para ti! Si tienes más preguntas o necesitas asistencia adicional, no dudes en ponerte en contacto con nosotros. 
¡Gracias por ser parte de la comunidad Cranberry Chic!</div>
</div>
<div className="RecomendationSpacer" />
</div>
</div>

     );
}

export default FAQ;