import React from 'react';

const PinIcon = ({ color = "#696969", height = "15px", width = "14px" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 15" fill="none">
      <g clipPath="url(#clip0_531_16035)">
        <path d="M12.25 6.66699C12.25 10.7503 7 14.2503 7 14.2503C7 14.2503 1.75 10.7503 1.75 6.66699C1.75 5.27461 2.30312 3.93925 3.28769 2.95468C4.27226 1.97012 5.60761 1.41699 7 1.41699C8.39239 1.41699 9.72774 1.97012 10.7123 2.95468C11.6969 3.93925 12.25 5.27461 12.25 6.66699Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 8.41699C7.9665 8.41699 8.75 7.63349 8.75 6.66699C8.75 5.70049 7.9665 4.91699 7 4.91699C6.0335 4.91699 5.25 5.70049 5.25 6.66699C5.25 7.63349 6.0335 8.41699 7 8.41699Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_531_16035">
          <rect width="14" height="14" fill="white" transform="translate(0 0.833496)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default PinIcon;
