import React from 'react';

function MoreIcon({ width = "32px", height = "32px", color = "#696969" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
      <path d="M16.0001 17.3334C16.7365 17.3334 17.3334 16.7364 17.3334 16C17.3334 15.2636 16.7365 14.6667 16.0001 14.6667C15.2637 14.6667 14.6667 15.2636 14.6667 16C14.6667 16.7364 15.2637 17.3334 16.0001 17.3334Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25.3333 17.3334C26.0697 17.3334 26.6667 16.7364 26.6667 16C26.6667 15.2636 26.0697 14.6667 25.3333 14.6667C24.597 14.6667 24 15.2636 24 16C24 16.7364 24.597 17.3334 25.3333 17.3334Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.66659 17.3334C7.40297 17.3334 7.99992 16.7364 7.99992 16C7.99992 15.2636 7.40297 14.6667 6.66659 14.6667C5.93021 14.6667 5.33325 15.2636 5.33325 16C5.33325 16.7364 5.93021 17.3334 6.66659 17.3334Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default MoreIcon;
