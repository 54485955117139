import React from 'react';

function MuteIcon({ width = "32px", height = "32px", color = "#696969" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
      <path d="M14.6667 6.66663L8.00008 12H2.66675V20H8.00008L14.6667 25.3333V6.66663Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M30.6667 12L22.6667 20" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.6667 12L30.6667 20" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default MuteIcon;
