import React from 'react';

const DiamondIcon = ({ width = "20px", height = "20px", color = "#696969" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
      <path d="M1.875 7.8125L10 16.875M1.875 7.8125L5 3.75M1.875 7.8125H18.125M10 16.875L18.125 7.8125M10 16.875L5 3.75M10 16.875L15 3.75M18.125 7.8125L15 3.75M15 3.75H5" stroke={color} strokeWidth="1.5" strokeLinejoin="round"/>
    </svg>
  );
}

export default DiamondIcon;
