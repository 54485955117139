import React, { useState, useEffect } from "react";
import PostForm from "../Components/PostForm";
import { useNavigate } from "react-router-dom";

import CarouselContentUpload from "../Components/CarouselContentUpload";
import LongPressButton  from "../Components/LongPressButton";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";

//Icons
import CloseIcon from "../../assets/SVG/CloseIcon";
import ResizeIcon from "../../assets/SVG/ResizeIcon";


export default function AddPhotos() {
  const navigate = useNavigate();
  // useEffect(() => {
  //   window.history.scrollRestoration = 'manual'
  // }, []);
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "MP4"];
  const [ratioSquare,setRadioSquare]=useState(false);
  const [isEditMode,setIsEditMode] = useState(false);
  const [files, setFiles] = useState([]);
  const [originalFiles, setOriginalFiles] = useState([]) ; 

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length <= 7) {
      setFiles(selectedFiles);
      setOriginalFiles(selectedFiles);
    } else {
      alert("Solo se permiten hasta 7 archivos");
      // Limpiar la selección si se excede el límite
      event.target.value = null;
    }
  };

  const formatFiles = (files) => {
    return files.map((file) => ({
      content_file: URL.createObjectURL(file),
      content_type: file.type,
      content_name : file.name
    }));
  };

  const onRemoveImage = (i) => {
    setFiles(prev => prev.filter((item, index) => index !== i));
    setOriginalFiles(prev => prev.filter((item, index) => index !== i));
    
  };

  const setNewImage = (i,newValue) => {
    setFiles(prev => prev.map((item, index) => index === i ? newValue : item));
  };

  const onError = (error) => {
    console.error(error);
  };



  return (
    <div className="UploadFotoLayout">
      <ScrollToTopOnMount />
      <div className="HeaderSpacer" style={{ height: "56px", width: "30px" }} />
      <div className='ProfileBox' >
          <div className='ProfileHeaderBar'>
              <button className='FullPostHeaderButton' onClick={() => navigate(-1)}>
                  <CloseIcon  />
              </button>
              <div className="ProfileEditTitle"> Nuevo Post </div>
              <button className='FullPostHeaderButton'>
                  <div style={{width:"20px", height:"24px"}} />
              </button>
          </div>
          <div className="UploadContentBox">
            { files.length > 0  ?
              <LongPressButton  onLongPress={() => setIsEditMode(true)}>
                <div className="PhotoPlacement" style={{height: ratioSquare ? "260px": "347px"}}>
                  <CarouselContentUpload data={formatFiles(files)} og_data={formatFiles(originalFiles)} onRemoveImage={onRemoveImage} setNewImage={setNewImage} size={260} ratioSquare={ratioSquare} isUpload={true} mini={false} />
                </ div>
              </LongPressButton>
              :
              <div className="PhotoPlacement" style={{height: ratioSquare ? "260px": "347px"}}>
                  <div className="UploadButton">
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*, video/*"
                    multiple
                    onChange={handleFileChange}
                    style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                  />
                  <label className="UploadLabel" htmlFor="fileInput" style={{ cursor: 'pointer' }}>Subir Multimedia</label>
                </div>
              </ div>
            }
            <button className="FloatingCarouselButton" onClick={() => setRadioSquare(!ratioSquare)}>
              <ResizeIcon />
            </button>
          </div>
          <PostForm files={files} ratioSquare={ratioSquare} />
        </div>
    </div>
    

  );
}
