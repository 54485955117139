import React from 'react';

const CarouselDot = ({ active, size, activeColor,normalColor}) => {
  const colorActive = activeColor ? activeColor : "#696969";
  const colorNormal = normalColor ? normalColor : "#E1E1E1";
  if (active) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
      <circle cx="4" cy="4.33301" r="4" fill={colorActive}/>
      </svg>
    );
  } else {
    if(size == "small"){
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="5" viewBox="0 0 4 5" fill="none">
          <circle cx="2" cy="2.6665" r="2" fill={colorNormal}/>
        </svg>
      );
    }
    else if (size == "smaller"){
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="3" viewBox="0 0 2 3" fill="none">
          <circle cx="1" cy="1.6665" r="1" fill={colorNormal}/>
        </svg>
      );
    }
    else if (size == "noShow"){
      return (<div/>);
    }
    else {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
          <circle cx="4" cy="4.33301" r="4" fill={colorNormal}/>
        </svg>
      );
    }

    
  }
};

export default CarouselDot;
