import React from 'react';

function CameraIcon({ width, height, color }) {
  return (
    <svg
      width={width || "40px"}
      height={height || "40px"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="camera-icon"
    >
      <path
        id="Vector"
        d="M38.3332 31.6667C38.3332 32.5507 37.982 33.3986 37.3569 34.0237C36.7317 34.6488 35.8839 35 34.9998 35H4.99984C4.11578 35 3.26794 34.6488 2.64281 34.0237C2.01769 33.3986 1.6665 32.5507 1.6665 31.6667V13.3333C1.6665 12.4493 2.01769 11.6014 2.64281 10.9763C3.26794 10.3512 4.11578 10 4.99984 10H11.6665L14.9998 5H24.9998L28.3332 10H34.9998C35.8839 10 36.7317 10.3512 37.3569 10.9763C37.982 11.6014 38.3332 12.4493 38.3332 13.3333V31.6667Z"
        stroke={color || "white"}
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M20.0002 28.3333C23.6821 28.3333 26.6668 25.3486 26.6668 21.6667C26.6668 17.9848 23.6821 15 20.0002 15C16.3183 15 13.3335 17.9848 13.3335 21.6667C13.3335 25.3486 16.3183 28.3333 20.0002 28.3333Z"
        stroke={color || "white"}
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CameraIcon;