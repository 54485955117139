import React, {useEffect} from 'react'
import TwoSquareIcon from '../../assets/SVG/TwoSquareIcon';
import FourSquareIcon from '../../assets/SVG/FourSquareIcon';


export default function SizeMenu(props) {
  const columns = props.props.columns
  const setColumns = props.props.setColumns;
  const setBreakpointCols = props.props.setBreakpointCols;
  const width = window.innerWidth;

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 480) {
        setColumns("l");
        setBreakpointCols({ default: 1 });
      }
    }

    // Llamamos a la función handleResize al cargar y al cambiar el tamaño de la ventana
    handleResize();
    window.addEventListener('resize', handleResize);
    
    // Limpia el event listener cuando el componente se desmonta
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Solo se ejecutará una vez al cargar el componente


  if ( width < 480 ){
    return (<div />)
  }
  else {
  return (
    <div className="ShowDotsBar">
      <button
        id="ShowDotsButtons"
        onClick={() => {
          setColumns("l");
          setBreakpointCols({
            default: 1,
          });
        }}
      >
        <TwoSquareIcon color={columns === "l" ? "#7D7D7D"  : ""} />
      </button>
      <button
        id="ShowDotsButtons"
        onClick={() => {
          setColumns("m");
          setBreakpointCols({
            default: 4,
            1300: 3,
            900: 2,
          });
        }}
      >
        <FourSquareIcon  color={columns === "m" ? "#7D7D7D"  : ""}/>
      </button>
      { width > 768 &&
          <button
          id="ShowDotsButtons"
          onClick={() => {
            setColumns("s");
            setBreakpointCols({
              default: 4,
              1300: 4,
              700: 2,
            });
          }}
        >
          <TwoSquareIcon color={columns === "s" ? "#7D7D7D"  : ""} />
          <FourSquareIcon  color={columns === "s" ? "#7D7D7D"  : ""}/>
        </button>
      }
    </div>
  );
}
}
