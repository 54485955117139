import React, { useEffect, useState } from 'react';
import Form from "react-bootstrap/Form";
import { NotifyMeAPI, NotifyMeUserAPI } from '../../API';
import CloseIcon from '../../assets/SVG/CloseIcon';

function NotifyMe(props) {
    const postID = props.postID;
    const [show, setShow] = useState("");
    const [email, setEmail] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [me, setMe] = useState();

    useEffect(() => {
        setMe(JSON.parse(localStorage.getItem("me")));
    }, []);

    const handleChecked = (event) => {
        event.stopPropagation()
        setIsChecked(true);
        setShow(true);
        if(me){
            NotifyMeUserAPI(postID).then(res=> console.log(res))
        }
        // Cerrar el Snackbar automáticamente después de 10 segundos
        setTimeout(() => {
            setShow(false);
        }, 5000); // 10000 milisegundos = 10 segundos
    };

    const handleCloseSnackbar = () => {
        setShow(false);
    };

    return (
        <>
            <div className="RecomendationCheckIn" onClick={(event) => handleChecked(event)}>
                <input
                    type="checkbox"
                    className="SmallCheck"
                    checked={isChecked}
                />
               
                ¡Avísame cuando estén las recomendaciones Cranberry Chic!

            </div>
            {
              me && show  && 
              <button className={`SnackBar ${show ? 'show' : ''}`} style={{ bottom: "120px" }} onClick={handleCloseSnackbar}>
                ¡Te llegará un e-mail cuando estén los recomendados disponibles!
                <CloseIcon color='#fafafa' onClick={(e) => { e.stopPropagation(); e.preventDefault(); setShow(false) }} />
              </button>
            }
            
            {show && !me &&
                <Form
                    style={{ maxWidth: "300px" }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        NotifyMeAPI(email, postID).then(() => {
                            alert("Atento a tu mail para notificaciones");
                        }).then(() => setShow(false))
                    }}>
                    <div
                        className='ModalCenteredLayout'
                        onClick={() => setShow(false)}
                    >
                        <div
                            className='ModalCenteredBox'
                            onClick={(e) => e.stopPropagation()}
                        >
                            <button className='ModalCloseButton' onClick={() => setShow(false)}>
                                <CloseIcon width='20px' height='20px' />
                            </button>

                            <div className='ModalTitle'> Notifícame</div>
                            <div className='ModalBody'>
                            Déjanos tu correo y te avisaremos cuando esté disponible la mejor curatoría para este look. </div>
                            <div className="ProfileInputBox">
                                <div className="PostFormMultiSelect">
                                    <Form.Control
                                        as="input"
                                        type="email"
                                        onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
                                    />
                                </div>
                            </div>
                            <button className="ModalButton" type='submit'> Acepto </button>
                            <button className='ModalSecondaryButton' > Volver </button>
                        </div>
                    </div>
                </Form>
            }
        </>
    )
}

export default NotifyMe;
