
import React, { useEffect, useState } from "react";
import { LatestPostMagApi } from "../../API";
import Carousel from "react-simply-carousel";
import ImageComponent from "./ImageComponent";



export default function CranMagEmbed(props) {
  const [data, setData] = useState();
  const [activeSlide, setActiveSlide] = useState(0);
  const size = props.size

  useEffect(() => {
    LatestPostMagApi().then((res) => {
      if (res) {
        setData(res.slice(0,10));
      }
    });
  }, []);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };


   const HtmlToReactParser = require("html-to-react").Parser;
   let htmlToReactParser = new HtmlToReactParser();

     // Función para cambiar al siguiente slide
  const changeSlide = () => {
    setActiveSlide((prevSlide) => (prevSlide + 1) % 10); // Calcula el siguiente slide
  };

  // Efecto para actualizar el slide activo cada 5 segundos
  useEffect(() => {
    const intervalId = setInterval(changeSlide, 7000); // Cambia el slide cada 5 segundos

    // Limpia el intervalo cuando el componente se desmonta o actualiza
    return () => clearInterval(intervalId);
  }, []); // El efecto se ejecuta solo una vez al montar el componente


   return(
    <div className="MagazineCarouselContainer" style={{width: size,}}>
         {data && data.length > 0  &&
         <Carousel
         containerProps={{
           className: "MagazineCarousel",
         }}
         activeSlideIndex={activeSlide}
         activeSlideProps={{
           className: "ActiveSlide",
         }}
         onRequestChange={setActiveSlide}
         forwardBtnProps={{
           children: "",
           style: {
             width: 0,
             height: 0,
             minWidth: 0,
             alignSelf: "center",
             border: "none",
             background: "none",
           },
         }}
         backwardBtnProps={{
           children: " ",
           style: {
             width: 0,
             height: 0,
             minWidth: 0,
             alignSelf: "center",
             border: "none",
             background: "none",
           },
         }}
         dotsNav={{
           show: false,
           itemBtnProps: {
             style: {
               height: 10,
               width: 10,
             },
           },
           activeItemBtnProps: {
             style: {
               height: 10,
               width: 10,
             },
           },
         }}
         centerMode={true}
         itemsToShow={1}
         speed={400}
       >
        <BannerComponent src="https://magazine.cranberrychic.com/wp-content/uploads/2023/08/MAG_Mesa-de-trabajo-1-1536x367.jpg"  width={size} height={Math.round(size /3)} />
         {data && data.map((item, index) => {
          return(
            <BannerComponent src={item.jetpack_featured_media_url}  width={size} height={Math.round(size /3)} top={"80%"} horizontal={true} />
         )})}
     </Carousel>}
      { activeSlide > 0 && data ?
       <div className="BannerTitle" style={{width:size+"px"}}>
       {htmlToReactParser.parse(data[activeSlide-1]?.title?.rendered)}
        </div>  
      :
      <div className="BannerTitle" style={{width:size+"px"}}>
      La nueva Cran Mag 2024
      </div>
      }

      </div>
   )
}



const BannerComponent = ({ src, height, width,onLoad, top, horizontal }) => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoaded = () => {
    if (!loaded){
      setLoaded(true);
      onLoad();
    }
  };


  return (
    <div style={{backgroundColor:"white", position: 'relative', height: `${height}px`, width: `${width}px`, overflow: 'hidden' }}>
    <img
      src={src}
      alt="imagen"
      style={{
        position: 'absolute',
        top: top? top : '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: horizontal ? "100%" : 'auto',
        height: horizontal ? 'auto':'100%',
        opacity: loaded ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
      }}
      onLoad={handleImageLoaded}
      loading="lazy"
    />
  </div>
  );
};
