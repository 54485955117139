import React from "react";

const CBCLogo = ({ color = "#606060", height = "526px", width = "411px" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 411.1 527.72" height={height} width={width}>
      <defs>
        <style>{`.cls-1{fill:${color};}`}</style>
      </defs>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path
            className="cls-1"
            d="M398.34,165.58q-7.39-22.15-17.8-46.67a191.49,191.49,0,0,0-26.86-45,131.75,131.75,0,0,0-41-33.58Q288.15,27.2,251.91,27.22q-49,0-77.26,18.12T132,95.73Q117.54,128,113.51,172.64t-4,97.75q0,69.84,8.06,115.54t25.18,72.54q17.14,26.85,44.33,37.62t64.84,10.74q30.22,0,54.74-12.08A152,152,0,0,0,350,462.83a186.18,186.18,0,0,0,31.58-45.34,228.12,228.12,0,0,0,18.81-53.06H411.1v142.4H400.36c-2.25-5.37-4.93-9.05-8.07-11.08s-7.83-3-14.11-3q-8.06,0-20.82,3.69t-29.21,8.07q-16.48,4.33-35.95,8.06a220.71,220.71,0,0,1-41,3.68,242.77,242.77,0,0,1-94.72-19.13A256.31,256.31,0,0,1,20.82,366.44Q0,321.44,0,269,0,212,23.17,165.58T82.62,86.33a266,266,0,0,1,80.94-50.71q44.69-17.82,87.67-17.81a187.33,187.33,0,0,1,40,4q18.46,4.05,34.26,8.39T354,38.63q12.75,4,21.49,4,9.4,0,14.1-2.36t6.72-11.76h10.76v137Z"
          />
          <polygon className="cls-1" points="135.2 527.72 126.27 524.18 335.08 0 344.01 3.56 135.2 527.72" />
        </g>
      </g>
    </svg>
  );
};

export default CBCLogo;
