import React from 'react';

const FilterIcon = ({ width = "32px", height = "32px", color = "#696969" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.2012 22H26.8012" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.20117 22H10.0012" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.201 21.9999C17.201 22.7118 16.9899 23.4078 16.5944 23.9998C16.1989 24.5918 15.6367 25.0532 14.979 25.3257C14.3213 25.5982 13.5975 25.6696 12.8992 25.5308C12.201 25.3921 11.5595 25.0494 11.056 24.5461C10.5524 24.0427 10.2094 23.4014 10.0703 22.7032C9.93126 22.005 10.0023 21.2812 10.2746 20.6234C10.5468 19.9655 11.008 19.4032 11.5998 19.0074C12.1916 18.6116 12.8874 18.4002 13.5994 18.3999C14.0723 18.3997 14.5406 18.4927 14.9775 18.6735C15.4145 18.8543 15.8115 19.1194 16.146 19.4538C16.4805 19.7881 16.7458 20.185 16.9268 20.6219C17.1078 21.0587 17.201 21.527 17.201 21.9999Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.6012 10H5.20117" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M26.8011 9.9999C26.8011 9.28796 26.59 8.592 26.1945 8.00002C25.799 7.40803 25.2368 6.94661 24.5791 6.67409C23.9214 6.40157 23.1976 6.33018 22.4993 6.46896C21.8011 6.60774 21.1596 6.95045 20.6561 7.45375C20.1525 7.95706 19.8095 8.59837 19.6704 9.2966C19.5314 9.99482 19.6024 10.7186 19.8746 11.3765C20.1469 12.0343 20.6081 12.5967 21.1999 12.9924C21.7917 13.3882 22.4875 13.5996 23.1995 13.5999C24.1544 13.5999 25.0702 13.2207 25.7456 12.5456C26.421 11.8705 26.8007 10.9548 26.8011 9.9999Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default FilterIcon;
