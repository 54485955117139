import React from 'react';

const BadgeIcon = ({ width = "12px", height = "12px" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6" cy="6" r="5.5" fill="#F7E5E9" stroke="#B33553"/>
    </svg>
  );
}

export default BadgeIcon;
