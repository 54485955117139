import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from "../../assets/SVG/CloseIcon"
import CBCLogo from "../../assets/SVG/CBCLogo"

const ModalCreator = ({ setShow }) => {
    let navigate = useNavigate()
    const [clicked,setClicked] = React.useState(false);

    return (
      <div
        className='ModalCenteredLayout'
        onClick={() => setShow(false)}
      >

        { !clicked ? 
        (<div
            className='ModalCenteredBox'
            onClick={(e) => e.stopPropagation()}
        >
            <button className='ModalCloseButton' onClick={() => setShow(false) }>
            <CloseIcon width='20px' height='20px' />
            </button>
            <div className='ModalPhotoBox'>
                <div className='ModalPhoto' style={{backgroundImage: `url(${require('../../assets/graficas/IMG.png')})` }}>
                    <CBCLogo  height='113px' width='148px'   color='#E5DED3AD'/>
                </div>
                <div className='ModalPhotoDivider'/>
            </div>
            <div className='ModalTitle'>¿Quieres convertirte en creadora de contenido?</div>
            <div className='ModalBody'>
                Construimos una comunidad de mujeres que influyen, inspiran y se conectan generando contenido en nuestras plataformas.
            </div>
            <div className='ModalBody'>
                Con esta nueva plataforma, próximamente, queremos ir descubriendo nuevos perfiles y voces que aporten contenido en Cranberry Chic.
            </div>
            <button className="ModalButton" onClick={() => setClicked(true) }> Quiero ser creadora </button>
            <button className='ModalSecondaryButton' 
            //onClick={() => {setShow(false); navigate("../../register")}}
            > Volver al feed </button>
        </div>)
        
        :
        <div
        className='ModalCenteredBox'
        onClick={(e) => e.stopPropagation()}
        >
            <button className='ModalCloseButton' onClick={() => setShow(false) }>
            <CloseIcon width='20px' height='20px' />
            </button>
            <div className='ModalTitle'>Muchas gracias por tu interés</div>
            <div className='ModalBody'>
            Ya tenemos tus datos y te iremos actualizando sobre las novedades de nuestra comunidad.
            ¡Ahora te invitamos a explorar la nueva plataforma Cranberry Chic para inspirarte en Moda, Lifestyle,Travel y más!
            </div>    
        <button className="ModalButton" onClick={() => setShow(false)}> explorar la app  </button>
        <button className='ModalSecondaryButton' 
        //onClick={() => {setShow(false); navigate("../../register")}}
        > Volver al feed </button>
        </div>

        }
        
      </div>
    );
  };


  export default ModalCreator;