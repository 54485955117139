import React from 'react';

const RegaloIcon = ({ width ="20px", height = "20px", color = "white" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path d="M10.5 14H6.5C6.23478 14 5.98043 13.8946 5.79289 13.7071C5.60536 13.5196 5.5 13.2652 5.5 13V12C5.5 11.7348 5.60536 11.4804 5.79289 11.2929C5.98043 11.1054 6.23478 11 6.5 11H17.5C17.7652 11 18.0196 11.1054 18.2071 11.2929C18.3946 11.4804 18.5 11.7348 18.5 12V13C18.5 13.2652 18.3946 13.5196 18.2071 13.7071C18.0196 13.8946 17.7652 14 17.5 14H13.5" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.5 14H17L16.566 20.071C16.548 20.3237 16.4348 20.5602 16.2491 20.7326C16.0635 20.9051 15.8194 21.0006 15.566 21H8.431C8.17764 21.0006 7.93348 20.9051 7.74786 20.7326C7.56223 20.5602 7.44899 20.3237 7.431 20.071L7 14H10.5" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5 11V21" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.5 21V11" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.50002 9.68802C9.10002 10.292 12 11 12 11C12 11 11.291 8.10002 10.688 7.50002C10.3979 7.20988 10.0044 7.04687 9.59402 7.04688C9.1837 7.04688 8.79017 7.20988 8.50002 7.50002C8.20988 7.79017 8.04688 8.1837 8.04688 8.59402C8.04687 9.00435 8.20988 9.39788 8.50002 9.68802Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.5 9.68802C14.9 10.292 12 11 12 11C12 11 12.709 8.10002 13.312 7.50002C13.4557 7.35636 13.6262 7.2424 13.8139 7.16464C14.0016 7.08689 14.2028 7.04688 14.406 7.04688C14.6092 7.04688 14.8104 7.08689 14.9981 7.16464C15.1858 7.2424 15.3563 7.35636 15.5 7.50002C15.6437 7.64369 15.7576 7.81425 15.8354 8.00196C15.9131 8.18966 15.9531 8.39085 15.9531 8.59402C15.9531 8.7972 15.9131 8.99838 15.8354 9.18609C15.7576 9.3738 15.6437 9.54436 15.5 9.68802Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 4.5V3" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.071 7.42914L20.132 6.36914" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22 14H23.5" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.92904 7.42914L3.86804 6.36914" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 14H0.5" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default RegaloIcon;
