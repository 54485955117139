import { setOptions } from 'filepond';
import React, { useEffect, useState, useRef } from 'react';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

function ScrollTracker(props) {
  const { data, setData, next, setNext,setScrolled,setWentBack } = props;

  const handleScroll = () => {
    // Get window scroll position
    const position = window.scrollY || window.pageYOffSet;
    // Save scroll position to sessionStorage
    sessionStorage.setItem('scrollPosition', position.toString());
    
  };

  useEffect(() => {
    // Add event listener when component mounts
    window.addEventListener('scroll', handleScroll);
    // Load scroll position from sessionStorage
    setScrolled(true);
    const savedScrollPosition = sessionStorage.getItem('scrollPosition');
    const wentBack = sessionStorage.getItem('wentBack');
    if (savedScrollPosition !== null && wentBack === 'true') {
      // If 'wentBack' is true, retrieve data from sessionStorage
      const savedData = JSON.parse(sessionStorage.getItem('userData'));
      const savedNext = sessionStorage.getItem('next');
      // Set the retrieved data
      setData(savedData);
      setNext(savedNext);
      // Move screen to the saved scroll position
      const options = {
        duration: 200,
        delay: 20,
        smooth: "easeInOutQuart"
      }
      scroll.scrollTo(savedScrollPosition,options ); 
      // Reset 'wentBack' to false
      sessionStorage.setItem('wentBack', 'false');
    } else {
      // Save data to sessionStorage
      sessionStorage.setItem('userData', JSON.stringify(data));
      sessionStorage.setItem('next',next);
    }
    // Cleanup: Remove event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [data, setData]);


  return (
    <div/>
  );
}

export default ScrollTracker;

