import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { updatePassword } from "../../API";
import CloseIcon from "../../assets/SVG/CloseIcon";
import NewAvatar from "../Components/AvatarEditor";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";


//Icons
import BackIcon from "../../assets/SVG/BackIcon";
import { InstagramIcon, TiktokIcon } from "../../assets/SVG/SocialIcons";
import DiamondIcon from "../../assets/SVG/DiamondIcon";

export default function Config() {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [message,setMessage] = React.useState();
  const [error,setError] = React.useState();
  const [deletedImage, setDeletedImage] = React.useState(false);
  

  useEffect(() => {
    const me = JSON.parse(localStorage.getItem("me"));
      if (me) {
        setData(me);
      }
  }, []);

  return (
    <div className='ProfileLayout'>
  <ScrollToTopOnMount />
  <Form
    onSubmit={(e) => {
      e.preventDefault();
      navigate("../../resetpassword")
    }}>

    <div className="HeaderSpacer" style={{ height: "56px", width: "30px" }} />
    <div className='ProfileBox'>
      <div className='ProfileHeaderBar'>
        <button className='FullPostHeaderButton' onClick={() => navigate(-1)}>
          <BackIcon />
        </button>
        <div className="ProfileEditTitle"> Configuraciones de mi cuenta </div>
        <button className='FullPostHeaderButton'>
          <div style={{ width: "20px", height: "24px" }} />
        </button>
      </div>
      <div className="ProfileEditBody">
        <div className="ProfileInputBox">
          Contraseña y seguridad
          <span className="LongText"> Por tu seguridad solo puedes restablecer tu contraseña a través de tu correo electrónico en el siguiente enlace. Si no tienes acceso a ese enlace, por favor, comunícate con nosotros en ayuda_app@cranberrychic.com</span>
        </div>
      </div>

      <button type="submit" className="RecentPostButton">
        Restablecer Contraseña
      </button>
    </div>

  </Form>
</div>
  );
}
