import React from 'react';

const ChevronDownIcon = ({ height, width, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "16px"}
      height={height || "16px"}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4 6L8 10L12 6"
        stroke={color || "#696969" }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronDownIcon;