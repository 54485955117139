
import React, { useEffect } from "react";
import { fetchMoreAPI, AlbumAPI, ProfileAPI } from "../../API";
import { useParams, useNavigate } from "react-router-dom";
import FilterMenu from "../Components/FilterMenu";
import PostScreen from "../Components/Post";
import SizeMenu from "../Components/SizeMenu";
import InfiniteScroll from "react-infinite-scroll-component";
import SliderMenu from "../Components/SliderMenu";
import Masonry from "react-masonry-css";
import Spinner from "react-bootstrap/Spinner";
import { Suspense } from 'react';
import BackIcon from "../../assets/SVG/BackIcon";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";


function AlbumPosts(props) {
  const [data, setData] = React.useState(null);
  const [next, setNext] = React.useState(null);
  const [columns, setColumns] = React.useState("m");
  const [user, setUser] = React.useState(null);
  const name = useParams()?.name;
  const album = useParams()?.album;
  
  var options = [
    { label: "Todo", value: 0 },
    { label: "Looks", value: 1 },
    { label: "Travel", value: 2 },
    {label: "Trends", value: 3 }, //solo sube admins
    { label: "Lifestyle", value: 4 },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    ProfileAPI(name).then((checkuser) => {
      if (checkuser) {
        setUser(checkuser);
      }
    })

  }, []);


  useEffect(() => {
    if(user){
      const selected_album = options.find(option => option.label === album);
      AlbumAPI(user.id, selected_album?.value).then((res) => {
        setData(res.results);
        setNext(res.next);
      });
    }
  }, [user]);


  const fetchMore = () => {
    fetchMoreAPI(next).then((res) => {
      setData((data) => {
        if (res?.results) {
          return [...data, ...res.results];
        } else {
          return data;
        }
      });
      setNext(res.next);
    });
  };

  const [breakpointCols, setBreakpointCols] = React.useState({
    default: 4,
    1300: 3,
    900: 2,
  });

  
  const LoadingAnimation = () => {
    return (
      <div style={{ height: "100vh", width: "100vw", backgroundColor: "blue" }}>
        Estamos cargando los mejores estilos para ti !
      </div>
    );
  };

  return (
    <div className="AlbumContainer">
      {/* <SliderMenu setAlbumFilter={setAlbumFilter} setTagFilter={setTagFilter} fixed={true} /> */}

      {/* 
      <Suspense fallback={<LoadingAnimation />}>
      
      <div> */}
      <ScrollToTopOnMount />
      <div className="HeaderSpacer" style={{ height: "100px", width: "30px" }} />
      <div className='AlbumHeaderBackground' style={{ minHeight:"unset", marginRight:"8px !important",justifyContent:"center !important"}} >
            <div className='ProfileHeaderBar AlbumHeader'>
                <button className='FullPostHeaderButton'  onClick={() => navigate(-1)}>
                    <BackIcon  />
                </button>
                <div className="AlbumTitle">
                    <div>{album ? album.toUpperCase() : "TODO"} </div>
                    <div className="AlbumUserTitle"> {user?.username}</div> 
                </div>
                <button className='FullPostHeaderButton'>
                    <div style={{width:"20px", height:"24px"}} />
                </button>
            </div>
      </div>
      {/* <FilterMenu albumFilter={albumFilter} tagFilter={tagFilter} setTagFilter={setTagFilter} /> */}
      <SizeMenu props={{ columns: columns, setBreakpointCols: setBreakpointCols, setColumns: setColumns }} />
      <InfiniteScroll
        dataLength={data ? data.length : 5}
        next={fetchMore}
        hasMore={next ? true : false}
        loader={
          <div className="Loader">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }

      >
        <Masonry
          breakpointCols={breakpointCols}
          className={columns === "l" ? "my-masonry-grid my-masonry-grid-large" : "my-masonry-grid"}
          columnClassName="my-masonry-grid_column"
        >
          {data
            ? data.map((post, index) => {
              return (
                <PostScreen className="PostScreen" key={index} data={post} size={columns} index={index} />
              );
            })
            : " "}
        </Masonry>

        <div className="FeedSpacer" />
      </InfiniteScroll>

      {/* </div>
      </Suspense> */}
    </div>
  );
}

export default AlbumPosts;
