import React, { useEffect, useState,useRef } from "react";
import { ResetPasswordAPI } from "../../API";
import { useParams } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import UserIcon from "../../assets/SVG/UserIcon";
import WarningIcon from "../../assets/SVG/WarningIcon";
import CBCLogo from  "../../assets/SVG/CBCLogo";
import { useNavigate } from "react-router-dom";
import EmailIcon from "../../assets/SVG/EmailIcon";

export default function ResetPassword() {
  const params = useParams();
  const [emailMessage,setEmailMessage] = useState();
  const [email, setEmail] = useState();
  const [errorMessage,setErrorMessage] = useState();
  const [message,setMessage] = useState();
  const invalid_email = "Formato mail inválido";

  const navigate = useNavigate()



  function isEmail(value) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(value)) {
      setEmailMessage(invalid_email);
    } else {
      setEmailMessage(null);
    }
  }


  useEffect(() => {
    setErrorMessage(null)
  }, [email]);




  



 
  return (
    <div className="PrincipalBox">
       <CBCLogo width="100" height="100" color="white" />
       <Form className="LoginForm" 
            onSubmit={(e) => {
              e.preventDefault();
              if(email && !emailMessage){
                ResetPasswordAPI(email).then(res => {
                  if(res.status){

                    setMessage(res.message)
                  }
                  else{
                    setErrorMessage(res.message)
                  }
                });
              }
              if(!email){
                const message = ["Por favor completar ", (!email ? "email, " : "")]
                const filteredMessage = message.filter(item => item !== "");
                if(filteredMessage.length === 2){
                  const final_message = filteredMessage.join(' ').slice(0,-2)
                  setErrorMessage(final_message)
                }
                else{
                  const last = filteredMessage.pop().slice(0,-2);
                  const final_message = filteredMessage.join(' ').slice(0,-2) + " y " + last;
                  setErrorMessage(final_message)
                }
              }
        }}>

        { (errorMessage || emailMessage) && 
        < div  className="ErrorText">
          <WarningIcon /> 
          <div style={{width:"80%"}}>{errorMessage} {emailMessage} </div>
        </div>}

        {!message  && 
        <div className="LoginText">
          Enviaremos un correo con las instrucciones para restablecer tu contraseña
        </div>}
       
       {!message &&
        <div className={!email &&  (errorMessage || emailMessage) ?"LoginInput ErrorBorder" :  "LoginInput"}>
          <UserIcon />
          <input
            disabled = {message ? true : false}
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value.trim().toLocaleLowerCase());
              isEmail(e.target.value.toLocaleLowerCase());
            }}
          />
        </div>}
        { message &&  
        <div  className="MessageText">
          <EmailIcon />
          <div className="LoginSideText">
          <span style={{fontWeight:"bold"}}>Revisa tu correo electrónico.</span>
          <span> Si tu cuenta está registrada, recibirás un email con las siguientes instrucciones para restablecer tu clave. </span>
          </div>
        </div>}
        
       { message ?
        (<button className="RecentPostButton" onClick={(e) => {e.preventDefault(); navigate(-1); }}>
        Volver
        </button>)
       :
       ( <button className="RecentPostButton" type="Submit" >
        Restrablecer Contraseña
        </button>)
        }

        { !message && 
          <button
            className="LinkButtonDecoration"
            onClick={() => navigate(-1)}
          >
            Volver
          </button>}
      </Form>
    </div>
  );
}
