import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { PostAPI,ProductAPI, isWishlistProductAPI, addWishlistProductAPI, deleteWishlistProductAPI, BrandAPI, GarmentBrandAPI } from "../../API";
import Carousel from "react-simply-carousel";
import PostScreen from '../Components/Post';
import ScrollToTopOnMount from '../Components/ScrollToTopOnMount';

// Icons
import BookmarkIcon from "../../assets/SVG/BookmarkIcon";
import ShareModal from "../Components/ShareModal";
import BackIcon from '../../assets/SVG/BackIcon';
import ImageComponent from '../Components/ImageComponent';



export default function Product() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isWish, setIsWish] = useState(false);
    const [product, setProduct] = useState(null);
    const [posts,setPosts] = useState(null);
    const [brand,setBrand] = useState();
    const [activeSlide, setActiveSlide] = useState(0)

  
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          if (id) {
              ProductAPI(id).then(res => {
                setProduct(res)
                BrandAPI(res.brand).then(brand => setBrand(brand))
              })
              isWishlistProductAPI(id).then(res => setIsWish(res.status))
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      if (id) {
        fetchData();
      }
    }, [id]);
  
    const handlePressWish = async () => {
      try {
        if (!isWish) {
          await addWishlistProductAPI(id);
          setIsWish(true);
        } else {
          await deleteWishlistProductAPI(id);
          setIsWish(false);
        }
      } catch (error) {
        console.error("Error updating wishlist:", error);
      }
    };
    

    const HtmlToReactParser = require("html-to-react").Parser;
    let htmlInput = product?.description;
    let htmlToReactParser = new HtmlToReactParser();
    let reactElement = htmlToReactParser.parse(htmlInput);

    let box_size = window.innerWidth - (16);
    if(box_size > 600){
      box_size= 600
    }
    const image_size = window.innerWidth -110;


    useEffect(() => {
        const fetchPosts = async () => {
          const postIds = product?.posts
          const postPromises = postIds?.map(PostAPI); // Llama a PostAPI para cada post.id
    
          try {
            const fetchedPosts = await Promise.all(postPromises);
            setPosts(fetchedPosts);
          } catch (error) {
            console.error('Error fetching posts:', error);
          }
        };
    
        fetchPosts();
      }, [product?.posts]); 

      const pre_extension =  product?.content_file.split(".")
      const extension = pre_extension ? pre_extension[pre_extension?.length-1] : ""
    return (
      <div>
        <div className="FullPostLayoutMobile">
          <ScrollToTopOnMount />
            <div className="HeaderSpacer" style={{ height: "56px", width: "30px" }} />
            <div className="FullPostBoxMobile" style={{width : box_size + "px"}} >
                <div className="FullPostHeader">
                    <div className="FullPostHeaderLeft">
                        <button className="FullPostHeaderButton" onClick={() => navigate(-1)}>
                            <BackIcon />
                        </button>
                        <ShareModal specificLink={window.location.href} id={product?.id} contents={[{content_type:"image/"+ extension,content_file:product?.content_file}]} shareBody={"Mira este producto en Cranberry Chic : "+ product?.name}  />
                    </div>
                    <div className="FullPostHeaderRowButton">
                        <button className="FullPostHeaderButton" onClick={handlePressWish}>
                          <BookmarkIcon
                            height="20px"
                            width="20px"
                            color={isWish ? "#B33553" : ""}
                            fill={isWish ? "#EBC7D0" : ""}
                          />
                        </button>
                    </div>
                </div>
                <div style={{height:"40px", width:"10px"}} />
                <div className="ProductBox">
                    <div
                        className="ProductPicture"
                        style={{
                        width : image_size  + "px", height:image_size + "px",overflow:'hidden',display:"flex",justifyContent:"center",alignItems:"center"
                     }}>
                      <ImageComponent src={product?.content_file}  width={image_size} height={image_size}/>
                    </div>
                    <div className="ProductInfo">
                        <div className="ProductName">
                        {brand?.name?.toUpperCase()} {product?.name}
                        </div>
                    </div>
                    <div className="Divider" />
                    <div style={{width:"100%", display:"flex",justifyContent:'flex-end'}} >
                    <button className='ProductBarButton ButtonFilled'  onClick={() => window.open(product?.link, "_blank")}>
                            Comprar
                    </button>
                    </div>

                </div>
            </div>
        </div>
        <div className='PostsBox'>
          {posts?.map((post, i) => (
              <PostScreen className="PostScreen" key={i} data={post} size={"m"} index={i} />          
            ))}
          </div>
          <div className="HeaderSpacer" style={{ height: "100px", width: "30px" }} />
         
    </div>
    );
}