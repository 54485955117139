//LIBRARY IMPORTS
import React, { useEffect, useState } from "react";


// MY CODE IMPORTS
import CarouselContent from "./CarouselContent";
import ShareModal from './ShareModal';
import WishlistModal from './WishlistModal';



function PostScreen(props) {
  const size = props.size;
  const data = props.data;
  const onLoad = props.onLoad;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [boxSize, setBoxSize] = useState(0);
  const [barSize, setBarSize] = useState(0);
  const current_href = window.location.href.split('/')
  const URL = current_href[0] + "//" + current_href[2] + "/@" 

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  
  const [opacityLoader,setOpacityLoader] = useState(0);
  const handleCarouselLoaded = () => {
    setOpacityLoader(1);
  };


  useEffect(() => {
    let newBoxSize;
    let newBarSize;

    if (size === 's') {
      newBoxSize = Math.round((windowWidth - 8 * 5) / 4);
      if (newBoxSize > 250 ){
        newBoxSize = 250
      }
      newBarSize = newBoxSize - 16;
    } else if (size === 'm') {
      newBoxSize = Math.round((windowWidth - 8 * 3) / 2);
      if (newBoxSize > 300 ){
        newBoxSize = 300
      }
      newBarSize = newBoxSize - 16;
      
    } else {
      newBoxSize = windowWidth - 8 * 2;
      if (newBoxSize > 500 ){
        newBoxSize = 500
      }
      newBarSize = newBoxSize - 24;
    }

    setBoxSize(newBoxSize);
    setBarSize(newBarSize);
  }, [size, windowWidth]);

 





  function InfoBar(props) {
    const type = props.type;
      return (
            <div className="CardInfoActionsMedium" style={{width:barSize}}>
              <div className="CardInfoUserName">
                { windowWidth < 768 && windowWidth >= 480 && size != "l"  &&  data.user?.username?.toLowerCase()}
                { (windowWidth >= 768 || size==="l" )  &&  data.user?.full_name}
              </div>
              <div className="CardInfoButtonsMedium">
                  <div>
                  <ShareModal specificLink={URL +data?.user?.username+"/post/"+data?.id} id={data?.id} contents={data?.contents} shareBody={"Mira este post de @"+ data?.user?.username +" en Cranberry Chic" }/>
                  </div>
                  <WishlistModal id={data?.id} img={data?.contents[0]} />
              </div>
            </div>
          );    
    }

 
  return (
      <div className={size==="l"  ? "PostBox PostBoxLarge" : "PostBox PostBoxMedium"} 
      style={{width : boxSize ,
          minWidth: boxSize + "px",
          minHeight: data?.contents[0].content_ratio ==="1:1"  ? 
          boxSize + "px" : Math.round((boxSize / 3) * 4) + "px",
          opacity : opacityLoader,
         transition:" opacity 0.5s ease"
          }} >
            <CarouselContent username={data?.user?.username} id={data.id} data={data.contents}  size={barSize} mini={true} ratioSquare={data?.contents[0].content_ratio==="1:1" } onLoad={handleCarouselLoaded}/>
            <InfoBar type={size} /> 
      </div>
  );
}

export default PostScreen;
