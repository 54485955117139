import React, { useEffect, useState,useRef } from "react";
import { ActivateAPI } from "../../API";
import { useParams } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import UserIcon from "../../assets/SVG/UserIcon"
import LockIcon from "../../assets/SVG/LockIcon";
import CBCLogo from  "../../assets/SVG/CBCLogo";
import CodeIcon from "../../assets/SVG/CodeIcon";
import ShowIcon from "../../assets/SVG/ShowIcon";
import WarningIcon from "../../assets/SVG/WarningIcon";


export default function Activate() {
  const params = useParams();
  const pre_email = params.params?.split(",")[1]?.substring(6);
  const pre_code = params.params?.split(",")[0]?.substring(5);
  const [email, setEmail] = useState(pre_email ? pre_email : null);
  const [code, setCode] = useState(pre_code ? pre_code.match(/.{1,2}/g) : [null,null,null,null] );
  const [newPass, setNewPass] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [errorMessage,setErrorMessage] = useState();
  const [samePasswordError, setSamePasswordError] = useState();
  const [showPassword, setShowPassword] = useState(false);



  useEffect(() => {
    setErrorMessage(null)
  }, [email,newPass,confirmNewPassword,code]);





  useEffect(() => {
    if(confirmNewPassword  && newPass !== confirmNewPassword){
      setSamePasswordError("Contraseñas no coinciden")

    }
    else{
      setSamePasswordError(null);
    }
  }, [confirmNewPassword]);


    const inputRefs = useRef([]);


    const handleKeyPress = index => () => {
      const current_value = inputRefs.current[index]?.value
      if (index < 3 && current_value.length === 2 ) {
          inputRefs.current[index + 1].focus();
        }
                   
    };

    const notNull = (element) => element !== null;

  



 
  return (
    <div className="PrincipalBox">
       <CBCLogo width="100" height="100" color="white" />
       <Form className="LoginForm" 
            onSubmit={(e) => {
              e.preventDefault();
              if(email && confirmNewPassword ===newPass && code.every(notNull) && confirmNewPassword && newPass){
                ActivateAPI(email,code.join(""),newPass).then(res => {
                  if(res.status){
                    window.location.href= "/"
                  }
                  else{
                    setErrorMessage(res.message)
                  }
                });
              }
              if(!email || !confirmNewPassword || !newPass || !code.every(notNull)){
                const message = ["Por favor completar ", (!email ? "email, " : ""), (!newPass ? "contraseña, "  : "") ,(!confirmNewPassword ? "confirmar contraseña, " : "" ),(!code.every(notNull) ? "codigo, " : "")]  
                const filteredMessage = message.filter(item => item !== "");
                if(filteredMessage.length === 2){
                  const final_message = filteredMessage.join(' ').slice(0,-2)
                  setErrorMessage(final_message)
                }
                else{
                  const last = filteredMessage.pop().slice(0,-2);
                  const final_message = filteredMessage.join(' ').slice(0,-2) + " y " + last;
                  setErrorMessage(final_message)
                }
              }
        }}>

        { (errorMessage || samePasswordError) &&
        <div  className="ErrorText">
          <WarningIcon /> 
          <div style={{width:"80%"}}>{errorMessage} {samePasswordError} </div>
        </div>}

        
        <div className="LoginText">
        Ingresa el código que enviamos a tu correo
        </div>
       
         
        <div className="CodeInput">
          {[1,2,3,4].map((inputnumber, index) => (
            <div className={!code.every(notNull) &&  errorMessage ?"LoginInput ErrorBorder" :  "LoginInput"}>
              <input
                key={index}
                type="text"
                placeholder=""
                value={code? code[index] : null }
                onKeyUp={handleKeyPress(index)}
                ref={el => (inputRefs.current[index] = el)}
                onChange={(e) => {
                  setCode(prevCode => {
                    const updatedCode = [...prevCode]; // Crear una copia del estado anterior
                    updatedCode[index] = e.target.value.substring(0, 2).toUpperCase(); // Actualizar el valor en el índice correspondiente
                    return updatedCode; // Devolver el nuevo estado actualizado
                  }); 
                }}
              />
          </div>
          ))}
        </div>
        <div className={!email &&  errorMessage ?"LoginInput ErrorBorder" :  "LoginInput"}>
          <UserIcon  width="28px" height="28px"/>
          <input
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value.trim().toLocaleLowerCase());
            }}
          />
        </div>
        <div className={!newPass &&  errorMessage ?"LoginInput ErrorBorder" :  "LoginInput"}>
          <LockIcon width="28px" height="28px" />
          <input
             type={!showPassword ? "password" : "text"}
            value={newPass}
            placeholder="Nueva contraseña"
            onChange={(e) => {
              setNewPass(e.target.value);
            }}
          />
          <button
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            <ShowIcon width="20px" height="20px" show={!showPassword} />
          </button>
        </div>
        <div className={(!confirmNewPassword &&  errorMessage) || samePasswordError ?"LoginInput ErrorBorder" :  "LoginInput"}>
          <LockIcon width="28px" height="28px" />
          <input
             type={!showPassword ? "password" : "text"}
            value={confirmNewPassword}
            placeholder="Confirma tu nueva contraseña"
            onChange={(e) => {
              setConfirmNewPassword(e.target.value);
            }}
          />
          <button
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            <ShowIcon show={!showPassword} width="20px" height="20px" />
          </button>
        </div>

        <button className="RecentPostButton" type="Submit">
          Activar mi cuenta 
        </button>
      </Form>
    </div>
  );
}
