import React, { useState, useEffect, useRef } from 'react';

import PlayIcon from '../../assets/SVG/PlayIcon';
import FullScreenIcon  from '../../assets/SVG/FullScreenIcon';
import SoundIcon from "../../assets/SVG/SoundIcon";
import MuteIcon from "../../assets/SVG/MuteIcon";
 
const VideoWithTimer = ({ url, width, height, mini, isPlaying,isUpload,onLoad }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isPaused, setIsPaused] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [userInteracted, setUserInteracted] = useState(false); //
  const [loaded, setLoaded] = useState(false);
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const progressRef = useRef(null);


  const handleVideoLoaded = () => {
    setLoaded(true);
    onLoad();
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const updateTimer = () => {
        setCurrentTime(video.currentTime);
      };
      video.addEventListener('timeupdate', updateTimer);
      if (isPlaying) {
        video.volume = isMuted ? 0 : 0.5;
        video.play();
        setIsPaused(false);
      }
      if (isUpload){
        video.volume = isMuted ? 0 : 0.5;
        video.pause();
        setIsPaused(true);
      }
      return () => {
        video.removeEventListener('timeupdate', updateTimer);
      };
    }
  }, [isPlaying, isMuted]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(document.fullscreenElement !== null);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);





  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if(!userInteracted && isPaused){
        video.play();
        setIsPaused(false);
        setUserInteracted(true)
        setIsMuted(false)
      }
      else if ( isPaused){
        setUserInteracted(true)
        video.play();
        setIsPaused(false);
      }else {
        setUserInteracted(true)
        video.pause();
        setIsPaused(true);
        }
      }
  };

  const handleProgressChange = () => {
    const video = videoRef.current;
    const progress = progressRef.current.value;
    if (video) {
      const newTime = (progress * video.duration) / 100;
      video.currentTime = newTime;
    }
  };


  const handleFullScreen = () => {
    const video = videoContainerRef.current;
    if (video) {
      if (!isFullScreen) {
        if (video.requestFullscreen) {
          video.requestFullscreen();
        } else if (video.mozRequestFullScreen) { /* Firefox */
          video.mozRequestFullScreen();
        } else if (video.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) { /* IE/Edge */
          video.msRequestFullscreen();
        }
        setIsFullScreen(true);
        setIsMuted(false);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
          document.msExitFullscreen();
        }
        setIsFullScreen(false);
      }
    }
  };


  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div className='VideoContainer' ref={videoContainerRef} >
      <div className='VideoTimeStamp'>{formatTime(currentTime)}</div>
      {!loaded && <p style={{ width:"100%",textAlign: 'center', marginTop: '50%', transform: 'translateY(-50%)' }}></p>}
      { mini ? 
      
      (
        <video
        className='VideoPlayer'
        muted
        //autoPlay="true"
        // loop
        style={{ width : width +"px", height :  height+"px", objectFit: 'cover',opacity: loaded ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out' }}
        src={url}
        onLoadedData={handleVideoLoaded}
      >
        Tu navegador no soporta la etiqueta de video.
      </video>
      ) :
      (
        <video
        ref={videoRef}
        className='VideoPlayer'
        muted={isMuted |0| !isPlaying}
        // loop
        style={{ width : isFullScreen ? "100vw" : width +"px",  height :  isFullScreen ? "100vh" : height+"px", objectFit: isFullScreen?  " " : 'cover',opacity: loaded ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out', }}
        onClick={handlePlayPause}
        src={url}
        onLoadedData={handleVideoLoaded}
        >
          Tu navegador no soporta la etiqueta de video.
        </video>
      )
      }
      {(loaded && isPaused) &&
        <button className='FloatingCarouselButtonCenter' style={{top: isFullScreen ? "-50vh"  :"-"+Math.round(height/2 -30)+ "px"}} onClick={handlePlayPause}>
          <PlayIcon /> 
        </button>
      }
      { !mini  &&
          <button className='FloatingCarouselButtonCorner' style={{top:"-10px"}} onClick={handleFullScreen} >
            <FullScreenIcon width='15px' height='15px' />
          </button>
      }
      { !mini  && !isUpload &&
          <button className='FloatingCarouselButtonCorner' style={{top:"-50px"}} onClick={toggleMute} >
            {isMuted ?  <MuteIcon  width='15px' height='15px' color='white'  />  : <SoundIcon   width='15px' height='15px'  color='white' />}
          </button>
      }
      { isFullScreen &&
          <div className='ProgressBarContainer'>
            <div
              ref={progressRef}
              className="ProgressBar"
              style={{
                width: `${(currentTime / videoRef.current?.duration) * 100}%`,
              }}
            />
        </div>
            
      }


      
    </div>
  );
};

export default VideoWithTimer;
