import React from "react";

const RestaurantesIcon = ({ width = "24px", height = "24px", color = "#C7506C" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.6561 10.137C20.6561 16.1261 13.8062 21.7352 12.2933 22.8999C12.2092 22.9647 12.1061 22.9998 12 22.9998C11.8939 22.9998 11.7907 22.9647 11.7066 22.8999C10.1928 21.7343 3.34387 16.1261 3.34387 10.137C3.34387 5.09145 6.95347 1 12 1C17.0465 1 20.6561 5.09145 20.6561 10.137Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.19104 6.29004V9.17541C7.19104 9.68557 7.3937 10.1748 7.75444 10.5356C8.11518 10.8963 8.60445 11.099 9.11462 11.099C9.62478 11.099 10.1141 10.8963 10.4748 10.5356C10.8355 10.1748 11.0382 9.68557 11.0382 9.17541V6.29004"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.11462 6.29004V15.427"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9236 15.427V6.29004C13.9236 6.29004 16.809 7.73272 16.809 13.5035H13.9236"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RestaurantesIcon;
