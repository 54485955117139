import React from 'react';

const ShareIcon = ({ width, height, color, filled, filledColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "32px"}
      height={height || "32px"}
      viewBox="0 0 20 21"
      fill={filled ? filledColor : 'none'}
    >
      <path
        d="M3.33325 10.6665V17.3332C3.33325 17.7752 3.50885 18.1991 3.82141 18.5117C4.13397 18.8242 4.55789 18.9998 4.99992 18.9998H14.9999C15.4419 18.9998 15.8659 18.8242 16.1784 18.5117C16.491 18.1991 16.6666 17.7752 16.6666 17.3332V10.6665"
        stroke={color || "#696969"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3334 5.66634L10.0001 2.33301L6.66675 5.66634"
        stroke={color || "#696969"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 2.33301V13.1663"
        stroke={color || "#696969" } 
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShareIcon;
