import React from 'react';

const TwoSquareIcon = ({ height, width, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "28px"}
      height={height || "12px"}
      viewBox="0 0 28 12"
      fill="none"
    >
      <path
        fill={color ||  "#E1E1E1"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.31663L0 4.68337C0 2.09682 2.09682 0 4.68337 0L7.31663 0C9.90318 0 12 2.09682 12 4.68337L12 7.31663C12 9.90318 9.90318 12 7.31663 12L4.68337 12C2.09682 12 0 9.90318 0 7.31663ZM16 7.31663L16 4.68337C16 2.09682 18.0968 0 20.6834 0L23.3166 0C25.9032 0 28 2.09681 28 4.68337L28 7.31663C28 9.90318 25.9032 12 23.3166 12L20.6834 12C18.0968 12 16 9.90318 16 7.31663Z"
      />
    </svg>
  );
};

export default TwoSquareIcon;
