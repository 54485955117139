import React, { useState,  } from "react";
import Carousel from "react-simply-carousel";
import { useNavigate } from "react-router-dom";
import PhotoEditor from "./PhotoEditor"
import VideoWithTimer from "./VideoPlayer";

//Icons
import CarouselDotIcon from "../../assets/SVG/CarouselDotIcon";
import TrashIcon from   "../../assets/SVG/TrashIcon";
import EditIcon from "../../assets/SVG/EditIcon";

function CarouselContentUpload(props) {
  const size = props.size;
  const ratioSquare =  props.ratioSquare;  
  const mini = props.mini;
  const isUpload = true;
  const [activeSlide, setActiveSlide] = useState(0);
  const data = props.data;
  const og_data = props.og_data;
  const onRemoveImage = props.onRemoveImage;
  const setNewImage = props.setNewImage;
  const [show, setShow] = useState(false);
  

  const handleDotClick = (index) => {
    setActiveSlide(index);
  };
  


  return (
    <div>
      <div className="Carousel" style={{ width : size +"px" , height: ratioSquare ? size + "px":  Math.round((size / 3) * 4) + "px"}}>
        {size ? 
        <Carousel
          containerProps={{
            className: "CarouselContainer",
            style : { width : size +"px" , height: ratioSquare ? size + "px":  Math.round((size / 3) * 4) + "px"}
          }}
          activeSlideIndex={activeSlide}
          activeSlideProps={{
            className: "ActiveSlide",
          }}
          onRequestChange={setActiveSlide}
          forwardBtnProps={{
            children: "",
            style: {
              width: 0,
              height: 0,
              minWidth: 0,
              alignSelf: "center",
              border: "none",
              background: "none",
            },
          }}
          backwardBtnProps={{
            children: false,
            style: {
              width: 0,
              height: 0,
              minWidth: 0,
              alignSelf: "center",
              border: "none",
              background: "none",
            },
          }}
          dotsNav={{
            show: false,
            itemBtnProps: {
              style: {
                height: 0,
                width: 0,
              },
            },
            activeItemBtnProps: {
              style: {
                height: 0,
                width: 0,
              },
            },
          }}
          centerMode={true}
          itemsToShow={1}
          speed={400}
          swipeTreshold={5}
          touchSwipeTreshold={5}
          mouseSwipeTreshold={10}
          swipeRatio={0.1}
        >
          {data ? (
            data.map((content, index) => {
              return(
              <div
                className="ContentButton"
                style={{  width: size + "px", height: ratioSquare ? size + "px " :  Math.round((size / 3) * 4) + "px" }}
                key={index}
              >
                {content.content_type &&
                content.content_type.includes("image") ? (
                  <div
                  style={{
                        height: ratioSquare ? size + "px " :  Math.round((size / 3) * 4)+ "px" ,
                        width: size+"px",
                        backgroundImage: `url(${content.content_file})`, 
                        backgroundSize: "cover", 
                        backgroundPosition: "center" 
                      }}
                  />
                ) : (
                  <VideoWithTimer
                    url={content.content_file}
                    width={size}
                    height={ratioSquare ? size : Math.round((size / 3) * 4)}
                    mini={mini}
                    isPlaying={index===activeSlide}
                    isUpload={isUpload}
                  />
                )}
              
              {content.content_type &&
                content.content_type.includes("image") &&
              <button className='FloatingCarouselButtonCorner' style={{marginLeft: "-40px",marginTop: "180px"}} onClick={() => setShow(true)}  >
                  <EditIcon width='15px' height='15px' color="white" />
              </button>}
              <button className='FloatingCarouselButtonCorner' style={{marginLeft: "-40px",marginTop: "100px"}} onClick={() => {onRemoveImage(index);setActiveSlide(index==0 ? 0 : index-1)}}  >
                  <TrashIcon width='15px' height='15px' />
              </button>
              </div>
            )})
          ) : (
            <div />
          )}
        </Carousel>
        :
        <div />
        }
      </div>

      {data && data.length >1 ? (
        <div className={"CarouselDotBar"}>
        {data.map((_, index) => (
          (Math.abs(activeSlide-index)<3) || (activeSlide < 2 && index < 5 ) || (activeSlide >= data.length - 2 && index >= data.length - 5) ? (
            <div 
              className="CarouselDot"
              key={index}
              onClick={() => handleDotClick(index)}
            >
              <CarouselDotIcon
                active={index === activeSlide}
                size={
                  Math.abs(index - activeSlide) <=1
                    ? "normal"
                    : Math.abs(index - activeSlide) <=3
                    ? "small"
                    : "smaller"
                }
              />
            </div>
          ) : null
        ))}
      </div>
      ) : (
        <></>
      )}
      
      {show && <PhotoEditor   setNewImage={setNewImage} index={activeSlide} setShow={setShow} image={og_data[activeSlide]} setImage={setNewImage} ratioSquare={ratioSquare} size={size} />
    }
    </div>
  );
}

export default CarouselContentUpload;
