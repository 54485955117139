import React from 'react';

function NoContentMessage({ customMessage = "No se encontró contenido" }) {
    return (
        <div className='NoContentMessage'>
            <p>{customMessage}</p>
        </div>
    );
}

export default NoContentMessage;