import React from "react";

const ShowIcon = ({ show, color = "#696969", width = "32px", height = "32px" }) => {
  return (
    <>
      {show ? (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
          <path d="M1.3335 16C1.3335 16 6.66683 5.33331 16.0002 5.33331C25.3335 5.33331 30.6668 16 30.6668 16C30.6668 16 25.3335 26.6666 16.0002 26.6666C6.66683 26.6666 1.3335 16 1.3335 16Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
          <g clipPath="url(#clip0_92_2125)">
            <path d="M23.9199 23.92C21.6407 25.6574 18.8654 26.6198 15.9999 26.6667C6.66659 26.6667 1.33325 16 1.33325 16C2.99177 12.9092 5.2921 10.2088 8.07992 8.08001M13.1999 5.65335C14.1177 5.43852 15.0573 5.33113 15.9999 5.33335C25.3333 5.33335 30.6666 16 30.6666 16C29.8572 17.5142 28.892 18.9397 27.7866 20.2533M18.8266 18.8267C18.4604 19.2197 18.0188 19.5349 17.5281 19.7535C17.0375 19.9721 16.5078 20.0897 15.9707 20.0992C15.4336 20.1086 14.9001 20.0098 14.4021 19.8087C13.904 19.6075 13.4515 19.3081 13.0717 18.9282C12.6919 18.5484 12.3924 18.0959 12.1913 17.5979C11.9901 17.0998 11.8913 16.5663 11.9008 16.0292C11.9102 15.4921 12.0278 14.9625 12.2464 14.4718C12.465 13.9811 12.7803 13.5395 13.1733 13.1733" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.33325 1.33331L30.6666 30.6666" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_92_2125">
              <rect width="32" height="32" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default ShowIcon;
