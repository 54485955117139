import React , {useState , useEffect} from 'react';
import {
    ProfileAPI,
    AlbumAPI,
    LogoutAPI
  } from "../../API";
import {  useNavigate,useParams } from "react-router-dom";
import ShareModal from '../Components/ShareModal';
import FollowButton from "../Components/FollowButton";
import SliderMenu from '../Components/SliderMenu';
import VideoWithTimer from '../Components/VideoPlayer';
import Masonry from "react-masonry-css";
import ScrollToTopOnMount from '../Components/ScrollToTopOnMount';
import NoContentMessage from "../Components/NoContentMessage"


//Icons
import BackIcon from "../../assets/SVG/BackIcon";
import UserIcon from "../../assets/SVG/UserIcon";
import PinIcon from  "../../assets/SVG/PinIcon";
import ConfigIcon from '../Components/ConfigIcon';
import { TiktokIcon , InstagramIcon } from '../../assets/SVG/SocialIcons';
import UserXIcon from '../../assets/SVG/UserXIcon';
import ImageComponent from '../Components/ImageComponent';
import CBCLogo from '../../assets/SVG/CBCLogo';


function Profile(props) {
    const [data, setData] = useState(null);
    const [recentPosts,setRecentPosts] = useState(null)
    const [myProfile, setMyProfile] = useState(null); 
    const name = useParams()?.name;
    const [albumFilter, setAlbumFilter] = React.useState("0");
    const navigate = useNavigate();
    const [columns, setColumns] = React.useState("m");
    const [breakpointCols, setBreakpointCols] = React.useState({
      default: 3,
      1300: 3,
      700: 2,
    });

    let image_size= (window.innerWidth - 80) /2 ;
    if (image_size > 157){
      image_size = 157 ;
    } 
  
    var options = [
      { label: "todo", value: "0" },
      { label: "looks", value: "1" },
      { label: "travel", value: "2" },
      {label: "trends", value: "3" }, //solo sube admins
      { label: "lifestyle", value: "4" },
    ];

    const [showModal, setShowModal] = useState(false);
    const [LoadedPic, setLoadedPic] = useState(false);

    const handleShowConfigModal = (e) => {
      e.preventDefault();
      setShowModal(!showModal);
    } 

    const handleLoadProfilePic = () => {
     setLoadedPic(true)
    };

    useEffect(() => {
      const me = JSON.parse(localStorage.getItem("me"));
      if (me && data &&  (me.id === data.id)) {
        if(data.is_bio){
          setMyProfile(true);
        }
        else{
          navigate("/@"+data?.username+"/edit")
        }
      }
      else if(me && data &&  (me.id != data.id))  {
        if(data.is_bio){
          setMyProfile(false);
        }
        else{
          navigate("/notfound")
        }           
              }
    }, [data]);


useEffect(() => {
        ProfileAPI(name).then((data) => {
            if (data) {
              setData(data);
              setRecentPosts(data?.last_posts.slice(0, 6))
            }
          })
        
      }, []);




      
  useEffect(() => {
    const fetchData = async () => {
      if (albumFilter && albumFilter !== "0" && data) {
        try {
          const posts = await AlbumAPI(data?.id, albumFilter);
          if (posts) {
            setRecentPosts(posts.results.slice(0, 6));
          } else {
           setRecentPosts(null);
          }
        } catch (error) {
          console.error("Error fetching album posts:", error);
        }
      } else {
           setRecentPosts(data?.last_posts.slice(0, 6));
      }
    };
  
    fetchData();
  }, [albumFilter]);
      




  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };


    

    return (
      <div className='ProfileLayout'>
        <div className="HeaderSpacer" style={{ height: "56px", width: "30px" }} />
        <ScrollToTopOnMount />
        <div className='ProfileBox'>
          <div className='ProfileHeaderBar'>
            <div className='ProfileHeaderBarLeft'>
              <button className='FullPostHeaderButton' onClick={() => navigate(-1)}>
                <BackIcon  />
              </button>
            </div>
            <div className='ProfileHeaderBarRight'>
              <ShareModal specificLink={window.location.href}  shareBody={"Mira el perfil de @" + data?.username + " en Cranberry Chic"} contents={[{content_file : data?.profile_picture, content_type:"image/png"}]} />
              { myProfile ? 
                <div className='FullPostHeaderButton' onClick={(e) => handleShowConfigModal(e)}                >
                  <ConfigIcon />
                  {showModal && 
                      <div className='ConfigModal'>
                        <button className='ConfigOptionsButton' onClick={() => navigate("/@"+data?.username+"/edit")}>
                          Editar Perfil
                        </button>
                        <button className='ConfigOptionsButton' onClick={() => navigate("/@"+data?.username+"/settings")}>
                          Configuración
                        </button>
                        <div className='Divider' />
                        <button className='ConfigOptionsButton'  onClick={() => LogoutAPI().then(navigate("../../login"))} > 
                          <UserXIcon />
                          Cerrar Sesión
                        </button>
                      </div>
                  }
                </div>
                :
                <FollowButton user_id={data?.id} />
              }

            </div>
          </div>
          <div className='ProfileUserInfoBox'>
            <div className='ProfileUserImg'>
            {!LoadedPic && !data?.profile_picture  && 
            <div className='NameLogo'>
              <CBCLogo  height='80px' width='80px'/> 
            </div>
            }
            <ImageComponent src={data?.profile_picture} height={104} width={104} onLoad={handleLoadProfilePic} />    
            </div>
            <div className='ProfileInfo'>
              <div className='ProfileName'> 
              <div className='ProfileFullName'>
                {data?.full_name}
                <div className='ProfileBagde' />
              </div>
              <div className='ProfileData'>
                <div className='ProfileDataRow'>
                  <UserIcon width="14px" height="14px" /> {data?.username}
                </div>
                <div className='ProfileDataRow'>
                  <PinIcon width='14px' height='14px' /> {data?.country}
                </div>
              </div>
              </div>
              <div className='ProfileSocials'>
                <div className='ProfileSocialsCounter'>
                  <div className='ProfileCount'>
                    <div className='ProfileCountNumber'>
                    {data?.posts_qty}
                    </div>
                    Posts
                  </div>
                  {myProfile ? 
                    <div className='ProfileCount'>
                      <div className='ProfileCountNumber'>
                      {data?.followers_qty}
                      </div>
                      Seguidores
                    </div>
                  : <></>  
                  }
                  {myProfile ? 
                    <div className='ProfileCount'>
                      <div className='ProfileCountNumber'>
                      {data?.following_qty}
                      </div>
                      Seguidos
                    </div>
                  : <></>  
                  }
                </div>
                
                { myProfile ? 
                  <></> 
                  : 
                  <div className='ProfileSocialsButtons' >
                    {
                      data?.ig_user &&
                      <button className='ProfileSocialButton' onClick={() => openInNewTab("https://www.instagram.com/"+ data.ig_user + "/")}>
                        <InstagramIcon width='15px' height='15px' />
                      </button>
                    }
                    {
                      data?.tiktok_user &&
                      <button className='ProfileSocialButton' onClick={() => openInNewTab("https://www.tiktok.com/@"+ data.tiktok_user + "/")} >
                        <TiktokIcon width='20px' height='20px' />
                      </button>
                    }
                  </div>
                }
              
              </div>
             
            </div>
            

          </div>
          <div className='ProfileDescription'>
              {data?.profile_description}
          </div>
          <div className='Divider' />
          {myProfile ?
            <div className='MyProfileSection'>
              {/* <button className='ProfileInviteButton'>
                Invitar Amigos
              </button> */}
              <div className='ProfileSocialsButtons' >
                    {
                      data?.ig_user &&
                      <button className='ProfileSocialButton' onClick={() => openInNewTab("https://www.instagram.com/"+ data.ig_user + "/")}>
                        <InstagramIcon width='15px' height='15px' />
                      </button>
                    }
                     {
                      data?.tiktok_user &&
                      <button className='ProfileSocialButton' onClick={() => openInNewTab("https://www.tiktok.com/@"+ data.tiktok_user + "/")} >
                        <TiktokIcon width='20px' height='20px' />
                      </button>
                    }
              </div>
            </div>
           :
           <></>
          }
          <div className='AlbumMenu' >
            <SliderMenu setAlbumFilter={setAlbumFilter} fixed={false} albumFilter={albumFilter} />
            <div className='Divider' style={{marginTop:"-0px"}} />
            <div className="FadeNotFixed LeftFade"/>
            <div className="FadeNotFixed RightFade" />
          </div>
        <div className='RecentPostsBox'>
          <Masonry
                breakpointCols={breakpointCols}
                className={columns === "l" ? "my-masonry-grid my-masonry-grid-large" : "my-masonry-grid"}
                columnClassName="my-masonry-grid_column"
              >
                {recentPosts && recentPosts.length > 0 
                  ? recentPosts.map((post, index) => {
                      const post_file = post.contents[0].content_file

                      if (!post_file.includes('mp4')){
                        return (
                          <div onClick={() => navigate("/@"+data?.username+"/post/"+post.id)} className='RecentPost' style={{width:image_size+"px", height:post.contents[0].content_ratio ==="1:1" ? image_size +"px" : (image_size/3*4)+"px",backgroundImage: "url(" + post.contents[0].content_file + ")" }}   />
                        );  
                      }
                      else{
                        return(
                          <div onClick={() => navigate("/@"+data?.username+"/post/"+post.id)}  style={{width:image_size+"px", height:post.contents[0].content_ratio ==="1:1" ? image_size +"px" : (image_size/3*4)+"px", overflow:"Hidden"}}>
                               <VideoWithTimer
                                mini={true}
                                isPlaying={true}
                                isUpload={false}
                                url={post_file}
                                width={image_size}
                                height={ post.contents[0].content_ratio ==="1:1" ? image_size  : (image_size/3*4)}
                                />
                          </div>
                       
                        ); 
                      }
                       })
                  : <NoContentMessage customMessage='No se encontraron posts relacionados a ese filtro en este perfil' />}
          </Masonry>
        </div>
        {recentPosts && recentPosts.length > 0  ? 
        (<button  className='RecentPostButton' onClick={() => navigate("/@"+ data.username +"/posts"+ (albumFilter=== "0" ? ""  :  "/" + options[albumFilter].label.toLowerCase()))}>
            Ver Todo {  albumFilter !== "0"  && options[albumFilter].label}
         </ button> 
        ): 
        (<div/>)
        }
        </div>
        <div className="FeedSpacer"/>
      </div>
    );
}

export default Profile;