import React, { useState } from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

function SearchAutocomplete(props) {
    const size = props.size;
    const search = props.searched || "";
    const [currentOptions, setCurrentOptions] = useState([]);
    const [currentWritten, setCurrentWritten] = useState([]);
    let navigate = useNavigate();
    const items = [
        { id: 1, name: "Vestido rojo", name: "Vestido rojo" },
        { id: 2, name: "Camisa azul", name: "Camisa azul" },
        { id: 3, name: "Pantalones negros", name: "Pantalones negros" },
        { id: 4, name: "Falda verde", name: "Falda verde" },
        { id: 5, name: "Chaqueta blanca", name: "Chaqueta blanca" },
        { id: 6, name: "Bufanda amarilla", name: "Bufanda amarilla" },
        { id: 7, name: "Sudadera gris", name: "Sudadera gris" },
        { id: 8, name: "Abrigo marrón", name: "Abrigo marrón" },
        { id: 9, name: "Chaleco violeta", name: "Chaleco violeta" },
        { id: 10, name: "Traje rosa", name: "Traje rosa" },
        { id: 11, name: "Jersey naranja", name: "Jersey naranja" },
        { id: 12, name: "Corbata dorada", name: "Corbata dorada" },
        { id: 13, name: "Calcetines plateados", name: "Calcetines plateados" },
        { id: 14, name: "Zapatos beige", name: "Zapatos beige" },
        { id: 15, name: "Sombrero azul marino", name: "Sombrero azul marino" },
        { id: 16, name: "Guantes rosados", name: "Guantes rosados" },
        { id: 17, name: "Botas turquesa", name: "Botas turquesa" },
        { id: 18, name: "Chaqueta de cuero negro", name: "Chaqueta de cuero negro" },
        { id: 19, name: "Blusa crema", name: "Blusa crema" },
        { id: 20, name: "Pantalones cortos rojos", name: "Pantalones cortos rojos" },
        { id: 21, name: "Ana", name: "Ana" },
        { id: 22, name: "María", name: "María" },
        { id: 23, name: "Laura", name: "Laura" },
        { id: 24, name: "Sofía", name: "Sofía" },
        { id: 25, name: "Carmen", name: "Carmen" },
        { id: 26, name: "Marta", name: "Marta" },
        { id: 27, name: "Andrea", name: "Andrea" },
        { id: 28, name: "Patricia", name: "Patricia" },
        { id: 29, name: "Elena", name: "Elena" },
        { id: 30, name: "Paula", name: "Paula" },
        { id: 31, name: "Julia", name: "Julia" },
        { id: 32, name: "Rosa", name: "Rosa" },
        { id: 33, name: "Isabel", name: "Isabel" },
        { id: 34, name: "Clara", name: "Clara" },
        { id: 35, name: "Lucía", name: "Lucía" },
        { id: 36, name: "Raquel", name: "Raquel" },
        { id: 37, name: "Beatriz", name: "Beatriz" },
        { id: 38, name: "Natalia", name: "Natalia" },
        { id: 39, name: "Lorena", name: "Lorena" },
        { id: 40, name: "Sara", name: "Sara" },
        { id: 41, name: "García", name: "García" },
        { id: 42, name: "Rodríguez", name: "Rodríguez" },
        { id: 43, name: "López", name: "López" },
        { id: 44, name: "Martínez", name: "Martínez" },
        { id: 45, name: "Pérez", name: "Pérez" },
        { id: 46, name: "Sánchez", name: "Sánchez" },
        { id: 47, name: "González", name: "González" },
        { id: 48, name: "Fernández", name: "Fernández" },
        { id: 49, name: "Gómez", name: "Gómez" },
        { id: 50, name: "Díaz", name: "Díaz" },
        { id: 51, name: "Ruiz", name: "Ruiz" },
        { id: 52, name: "Alonso", name: "Alonso" },
        { id: 53, name: "Torres", name: "Torres" },
        { id: 54, name: "Ramírez", name: "Ramírez" },
        { id: 55, name: "Vázquez", name: "Vázquez" },
        { id: 56, name: "Navarro", name: "Navarro" },
        { id: 57, name: "Jiménez", name: "Jiménez" },
        { id: 58, name: "Moreno", name: "Moreno" },
        { id: 59, name: "Herrera", name: "Herrera" },
        { id: 60, name: "Castro", name: "Castro" },
        { id: 61, name: "Cian", name: "Cian" },
        { id: 62, name: "Índigo", name: "Índigo" },
        { id: 63, name: "Borgoña", name: "Borgoña" },
        { id: 64, name: "Ámbar", name: "Ámbar" },
        { id: 65, name: "Grisáceo", name: "Grisáceo" },
        { id: 66, name: "Canela", name: "Canela" },
        { id: 67, name: "Lavanda", name: "Lavanda" },
        { id: 68, name: "Carmesí", name: "Carmesí" },
        { id: 69, name: "Azul celeste", name: "Azul celeste" },
        { id: 70, name: "Púrpura", name: "Púrpura" },
        { id: 71, name: "Azafrán", name: "Azafrán" },
        { id: 72, name: "Coral", name: "Coral" },
        { id: 73, name: "Esmeralda", name: "Esmeralda" },
        { id: 74, name: "Amaranto", name: "Amaranto" },
        { id: 75, name: "Perla", name: "Perla" },
        { id: 76, name: "Marfil", name: "Marfil" },
        { id: 77, name: "Turquesa", name: "Turquesa" },
        { id: 78, name: "Topacio", name: "Topacio" },
        { id: 79, name: "Ocre", name: "Ocre" },
        { id: 80, name: "Granate", name: "Granate" },
        { id: 251, name: "Pantalón Negro" },
        { id: 252, name: "Pantalón Azul" },
        { id: 253, name: "Pantalón Gris" },
        { id: 254, name: "Pantalón Blanco" },
        { id: 255, name: "Pantalón Marrón" },
        { id: 256, name: "Pantalón Verde" },
        { id: 257, name: "Pantalón Rojo" },
        { id: 258, name: "Pantalón Amarillo" },
        { id: 259, name: "Pantalón Naranja" },
        { id: 260, name: "Pantalón Morado" },
        { id: 261, name: "Pantalón Rosa" },
        { id: 262, name: "Camisa Negra" },
        { id: 263, name: "Camisa Azul" },
        { id: 264, name: "Camisa Gris" },
        { id: 265, name: "Camisa Blanca" },
        { id: 266, name: "Camisa Marrón" },
        { id: 267, name: "Camisa Verde" },
        { id: 268, name: "Camisa Roja" },
        { id: 269, name: "Camisa Amarilla" },
        { id: 270, name: "Camisa Naranja" },
        { id: 271, name: "Camisa Morada" },
        { id: 272, name: "Camisa Rosa" },
        { id: 273, name: "Abrigo Negro" },
        { id: 274, name: "Abrigo Azul" },
        { id: 275, name: "Abrigo Gris" },
        { id: 276, name: "Abrigo Blanco" },
        { id: 277, name: "Abrigo Marrón" },
        { id: 278, name: "Abrigo Verde" },
        { id: 279, name: "Abrigo Rojo" },
        { id: 280, name: "Abrigo Amarillo" },
        { id: 281, name: "Abrigo Naranja" },
        { id: 282, name: "Abrigo Morado" },
        { id: 283, name: "Abrigo Rosa" },
        { id: 284, name: "Zapatos Negros" },
        { id: 285, name: "Zapatos Marrones" },
        { id: 286, name: "Zapatos Blancos" },
        { id: 287, name: "Zapatos Azules" },
        { id: 288, name: "Zapatos Grises" },
        { id: 289, name: "Zapatos Rojos" },
        { id: 290, name: "Zapatos Verdes" },
        { id: 291, name: "Zapatos Amarillos" },
        { id: 292, name: "Zapatos Naranjas" },
        { id: 293, name: "Zapatos Morados" },
        { id: 294, name: "Zapatos Rosas" },
        { id: 295, name: "Bufanda Negra" },
        { id: 296, name: "Bufanda Azul" },
        { id: 297, name: "Bufanda Gris" },
        { id: 298, name: "Bufanda Blanca" },
        { id: 299, name: "Bufanda Marrón" },
        { id: 300, name: "Bufanda Verde" },
        { id: 301, name: "Bufanda Roja" },
        { id: 302, name: "Bufanda Amarilla" },
        { id: 303, name: "Bufanda Naranja" },
        { id: 304, name: "Bufanda Morada" },
        { id: 305, name: "Bufanda Rosa" },
        { id: 306, name: "Guantes Negros" },
        { id: 307, name: "Guantes Azules" },
        { id: 308, name: "Guantes Grises" },
        { id: 309, name: "Guantes Blancos" },
        { id: 310, name: "Guantes Marrones" },
        { id: 311, name: "Guantes Verdes" },
        { id: 312, name: "Guantes Rojos" },
        { id: 313, name: "Guantes Amarillos" },
        { id: 314, name: "Guantes Naranjas" },
        { id: 315, name: "Guantes Morados" },
        { id: 316, name: "Guantes Rosas" },
        { id: 317, name: "Gorra Negra" },
        { id: 318, name: "Gorra Azul" },
        { id: 319, name: "Gorra Gris" },
        { id: 320, name: "Gorra Blanca" },
        { id: 321, name: "Gorra Marrón" },
        { id: 322, name: "Gorra Verde" },
        { id: 323, name: "Gorra Roja" },
        { id: 324, name: "Gorra Amarilla" },
        { id: 325, name: "Gorra Naranja" },
        { id: 326, name: "Gorra Morada" },
        { id: 327, name: "Gorra Rosa" },
        { id: 328, name: "Amarillo" },
        { id: 329, name: "Azul Claro" },
        { id: 330, name: "Azul Oscuro" },
        { id: 331, name: "Beige" },
        { id: 332, name: "Blanco" },
        { id: 333, name: "Cafe" },
        { id: 334, name: "Dorado" },
        { id: 335, name: "Gris" },
        { id: 336, name: "Naranjo" },
        { id: 337, name: "Negro" },
        { id: 338, name: "Plateado" },
        { id: 339, name: "Rojo" },
        { id: 340, name: "Rosado" },
        { id: 341, name: "Verde" },
        { id: 342, name: "Violeta" },
        { id: 343, name: "Turquesa" },
        { id: 344, name: "Calipso" },
        { id: 345, name: "Fucsia" },
        { id: 346, name: "Coral" },
        { id: 347, name: "Celeste" },
        { id: 348, name: "Verde Esmeralda" },
        { id: 349, name: "Verde Agua" },
        { id: 350, name: "Petróleo" },
        { id: 351, name: "Caqui" },
        { id: 352, name: "Blanco Invierno" },
        { id: 353, name: "Burdeo" },
        { id: 354, name: "Marrón" },
        { id: 355, name: "Bordeaux" },
        { id: 356, name: "Marfil" },
        { id: 357, name: "Camel" },
        { id: 358, name: "Floreado" },
        { id: 359, name: "Animal Print" },
        { id: 360, name: "Estampado" },
        { id: 361, name: "Zebra" },
        { id: 362, name: "Abrigo" },
        { id: 363, name: "Anillo" },
        { id: 364, name: "Aros" },
        { id: 365, name: "Bikini" },
        { id: 366, name: "Billetera" },
        { id: 367, name: "Blusa" },
        { id: 368, name: "Bolso" },
        { id: 369, name: "Botas" },
        { id: 370, name: "Botines" },
        { id: 371, name: "Buzo" },
        { id: 372, name: "Camisa" },
        { id: 373, name: "Camiseta" },
        { id: 374, name: "Cartera" },
        { id: 375, name: "Chalas" },
        { id: 376, name: "Chaleco" },
        { id: 377, name: "Chaqueta" },
        { id: 378, name: "Cinturon" },
        { id: 379, name: "Collar" },
        { id: 380, name: "Gorro" },
        { id: 381, name: "Guantes" },
        { id: 382, name: "Falda" },
        { id: 383, name: "Impermeable" },
        { id: 384, name: "Jeans" },
        { id: 385, name: "Lentes" },
        { id: 386, name: "Mini" },
        { id: 387, name: "Pantalon" },
        { id: 388, name: "Panties" },
        { id: 389, name: "Parka" },
        { id: 390, name: "Patas" },
        { id: 391, name: "Peto" },
        { id: 392, name: "Pijama" },
        { id: 393, name: "Polera" },
        { id: 394, name: "Pollera" },
        { id: 395, name: "Pulsera" },
        { id: 396, name: "Ropa Interior" },
        { id: 397, name: "Sandalias" },
        { id: 398, name: "Short" },
        { id: 399, name: "Sungas" },
        { id: 400, name: "Sweater" },
        { id: 401, name: "Tapado" },
        { id: 402, name: "Tops" },
        { id: 403, name: "Traje de Baño" },
        { id: 404, name: "Traje de Gala" },
        { id: 405, name: "Trench" },
        { id: 406, name: "Vestido" },
        { id: 407, name: "Zapatillas" },
        { id: 408, name: "Zapatos" },
        { id: 409, name: "Zuecos" },
        { id: 410, name: "Verde Oliva" },
        { id: 411, name: "Verde Militar" },
        { id: 412, name: "Mostaza" },
        { id: 413, name: "Rosa Viejo" },
        { id: 414, name: "Azul Marino" },
        { id: 415, name: "Rojo Rubí" },
        { id: 416, name: "Azul Rey" },
        { id: 417, name: "Azul Petroleo" },
        { id: 418, name: "Naranja" },
        { id: 419, name: "Rojo Coral" },
        { id: 420, name: "Negro Liso" },
        { id: 421, name: "Marrón Claro" },
        { id: 422, name: "Marrón Oscuro" },
        { id: 423, name: "Rojo Oscuro" },
        { id: 424, name: "Rojo Claro" },
        { id: 425, name: "Celeste Claro" },
        { id: 426, name: "Celeste Oscuro" },
        { id: 427, name: "Verde Claro" },
        { id: 428, name: "Verde Oscuro" },
        { id: 429, name: "Lila" },
        { id: 430, name: "Amarillo Claro" },
        { id: 431, name: "Amarillo Oscuro" },
        { id: 432, name: "Gris Claro" },
        { id: 433, name: "Gris Oscuro" },
        { id: 434, name: "Azul Claro" },
        { id: 435, name: "Azul Oscuro" },
        { id: 436, name: "Magenta" },
        { id: 437, name: "Fucsia Claro" },
        { id: 438, name: "Fucsia Oscuro" },
        { id: 439, name: "Turquesa Claro" },
        { id: 440, name: "Turquesa Oscuro" },
        { id: 441, name: "Lila Claro" },
        { id: 442, name: "Lila Oscuro" },
        { id: 443, name: "Verde Agua Claro" },
        { id: 444, name: "Verde Agua Oscuro" },
        { id: 445, name: "Verde Oliva Claro" },
        { id: 446, name: "Verde Oliva Oscuro" },
        { id: 447, name: "Verde Militar Claro" },
        { id: 448, name: "Verde Militar Oscuro" },
        { id: 449, name: "Mostaza Claro" },
        { id: 450, name: "Mostaza Oscuro" },
        { id: 451, name: "Rosa Viejo Claro" },
        { id: 452, name: "Rosa Viejo Oscuro" }
            
    ];

    const handleOnSearch = (string) => {
        const filteredOptions = items.filter(item =>
            item.name.toLowerCase().includes(string.toLowerCase())
        );
    
        // Agregar un objeto con id: 0, name: string y value: string a filteredOptions
        if (string.trim() !== "") {
            filteredOptions.unshift({ id: 0, name: string, value: string });
        }
    
        // Establecer las opciones filtradas como las opciones actuales
        setCurrentOptions(filteredOptions);
    
        // Filtrar los elementos basados en la cadena de búsqueda
        console.log(string);
        setCurrentWritten(string.toLowerCase());
    }
    

    const handleOnHover = (result) => {
        // el elemento sobre el que se pasa el ratón
        console.log(result)
    }

    

    const handleOnSelect = (item) => {
        navigate('/search/'+item.name.toLowerCase())
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }

    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    return (
        <Form onSubmit={() => navigate('/search/'+currentWritten) }>
            <div className="SearchWrapper" style={{ width: size}}>
                <ReactSearchAutocomplete
                    items={currentOptions}
                    inputSearchString={search}
                    onSearch={handleOnSearch}
                    showNoResults={false}
                    onSelect={handleOnSelect}
                    onFocus={handleOnFocus}
                    autoFocus
                    formatResult={formatResult}
                    placeholder="Buscar Tendencias, Inspiración..."
                />
            </div>
        </Form>
    );
}

export default SearchAutocomplete;
