import React, { useEffect } from 'react';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

const ScrollToTopOnMount = () => {
  useEffect(() => {
    const options = {
      duration: 0,
      delay: 0,
      smooth: "easeInOutQuart"
    }
    scroll.scrollToTop(options);
  }, []); // Se ejecuta solo una vez, al montar el componente

  return null; // Este componente no renderiza nada
};

export default ScrollToTopOnMount;
