import React from 'react';

function BottomBarShape({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "360px"}
      height={height || "91px"}
      viewBox="0 0 360 91"
      fill={color || "none"}
      className="custom-shape"
    >
      <g filter="url(#filter0_d_297_1653)">
        <path
          d="M144.284 43.4311C136.782 31.9332 126.167 20 112.438 20H-655V100H1015V20H247.822C234.093 20 223.478 31.9332 215.976 43.4311C208.307 55.1862 195.116 62.9445 180.13 62.9445C165.144 62.9445 151.954 55.1862 144.284 43.4311Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_297_1653"
          x="-671"
          y="0"
          width="1702"
          height="112"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_297_1653" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_297_1653" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default BottomBarShape;