import React from 'react';

const TiktokIcon = ({ width = "32px", height = "32px", color = "#696969" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
      <path d="M20.3419 6.76082L19.8593 6H16.9393V12.8523L16.9293 19.5455C16.9343 19.5952 16.9393 19.6499 16.9393 19.6997C16.9393 21.3754 15.5762 22.7429 13.8948 22.7429C12.2135 22.7429 10.8504 21.3804 10.8504 19.6997C10.8504 18.0239 12.2135 16.6564 13.8948 16.6564C14.2431 16.6564 14.5813 16.721 14.8947 16.8304V13.4888C14.5714 13.4341 14.2381 13.4043 13.8948 13.4043C10.4276 13.4092 7.60205 16.2337 7.60205 19.7046C7.60205 23.1755 10.4276 26 13.8998 26C17.372 26 20.1976 23.1755 20.1976 19.7046V11.7434C21.4561 13.0015 23.0828 14.2297 24.8836 14.6226V11.2064C22.9286 10.3411 20.9836 7.78518 20.3419 6.76082Z" stroke={color} strokeWidth="1.2" strokeLinejoin="round" />
    </svg>
  );
};

const PinterestIcon = ({ width = "32px", height = "32px", color = "#696969" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.5164 20.0356C15.2459 20.058 15.9048 20.9791 17.8344 20.9791C21.4583 21.0016 23.9527 18.3506 24.7292 14.8684C27.4354 2.80426 9.00997 1.83822 7.12742 11.7681C6.68032 14.1046 7.40981 16.8005 9.31588 17.6767C10.7749 18.3506 10.869 16.4186 10.4219 15.5873C8.44521 11.9029 10.8219 8.57798 13.9281 7.63442C16.8461 6.73578 18.9169 7.47716 20.4935 9.0273C22.5172 11.0268 21.5995 16.4635 19.1993 18.1934C18.3521 18.8 16.799 18.9348 15.9989 18.2158C14.3517 16.7556 16.8931 13.6553 16.5166 11.3862C16.1401 9.13963 12.4221 9.47662 12.2338 12.7791C12.1397 14.464 12.6574 14.9583 12.2574 16.6432C11.622 19.3391 9.69239 24.8433 11.0572 27C13.0574 26.1014 14.0458 20.7096 14.5164 20.0356Z" stroke={color} strokeWidth="1.2" />
    </svg>
  );
};

const WhatsappIcon = ({ width = "28px", height = "30px", color = "#696969" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 30" fill="none">
      <path d="M25.9822 9.05574C25.3251 7.48361 24.3818 6.07213 23.1841 4.85574C21.9864 3.65082 20.5979 2.69836 19.0504 2.03279C17.4499 1.34426 15.754 1 14.0052 1C12.2563 1 10.5605 1.34426 8.95998 2.03279C7.4125 2.69836 6.02401 3.63934 4.82631 4.85574C3.6286 6.07213 2.68529 7.48361 2.02814 9.05574C1.34979 10.6852 1 12.4295 1 14.2197C1 17.3525 2.0811 20.359 4.06315 22.7459L3.00323 29L8.642 26.2803C10.3273 27.0607 12.1185 27.4508 13.9946 27.4508C15.7434 27.4508 17.4393 27.1066 19.0398 26.418C20.5873 25.7525 21.9758 24.8115 23.1735 23.5951C24.3712 22.3787 25.3145 20.9672 25.9716 19.3951C26.65 17.7656 26.9998 16.0213 26.9998 14.2311C27.0104 12.4295 26.6606 10.6967 25.9822 9.05574Z" stroke={color} strokeWidth="1.2" />
      <path d="M19.4638 16.6758C18.9073 16.3975 18.5006 16.2262 18.2116 16.1192C18.0296 16.055 17.6014 15.8623 17.4516 15.98C16.9806 16.3654 16.4775 17.4571 15.9424 17.6605C14.6151 17.4036 13.3842 16.4938 12.4209 15.5626C11.9927 15.1559 11.2007 13.9999 11.0294 13.6895C10.9973 13.3684 11.5753 12.9402 11.7037 12.694C12.3673 11.9448 11.8643 11.4738 11.7787 11.1634C11.6288 10.8423 11.3719 10.2643 11.1472 9.79336C10.9545 9.48295 10.9116 9.0227 10.5691 8.85144C9.11344 8.10219 8.2786 9.60069 7.93608 10.3821C5.87028 15.3592 18.2865 24.8319 20.8125 18.3027C20.941 17.7354 20.8874 17.5214 20.6948 17.2645C20.3094 16.9969 19.8599 16.8792 19.4638 16.6758Z" stroke={color} strokeWidth="1.2" />
    </svg>
  );
};

const InstagramIcon = ({ width = "28px", height = "28px", color = "#696969" }) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
          <path d="M12.9928 15.5H3.00719C1.62731 15.5 0.5 14.3727 0.5 12.9928V3.00719C0.5 1.62731 1.62731 0.5 3.00719 0.5H12.9928C14.3727 0.5 15.5 1.62731 15.5 3.00719V12.9928C15.5 14.3789 14.3789 15.5 12.9928 15.5Z" stroke={color}/>
          <path d="M5.28034 10.726C6.00724 11.4529 6.97438 11.8533 8.00313 11.8533C9.03188 11.8533 9.99286 11.4529 10.7259 10.726C11.4528 9.99907 11.8532 9.03193 11.8532 8.00318C11.8532 6.97443 11.4528 6.00729 10.7259 5.28039C9.99902 4.55349 9.03188 4.15308 8.00313 4.15308C6.97438 4.15308 6.00724 4.55349 5.28034 5.28039C4.55344 6.00729 4.15303 6.97443 4.15303 8.00318C4.15303 9.03193 4.55344 9.99907 5.28034 10.726Z" stroke={color}/>
          <path d="M12.6036 4.02657C13.0119 4.02657 13.3429 3.6956 13.3429 3.28733C13.3429 2.87906 13.0119 2.5481 12.6036 2.5481C12.1954 2.5481 11.8644 2.87906 11.8644 3.28733C11.8644 3.6956 12.1954 4.02657 12.6036 4.02657Z" stroke={color}/>
      </svg>
  );
};

export { TiktokIcon, PinterestIcon, WhatsappIcon, InstagramIcon };
