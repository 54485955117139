import React, { useState, useEffect } from 'react';
import {  isWishlistProductAPI } from "../../API";
// Icons
import BookmarkIcon from "../../assets/SVG/BookmarkIcon";
import ImageComponent from './ImageComponent';


export default function ProductBox(props) {
    const { product, i,size } = props;
    const [isWish, setIsWish] = useState(false);
    
    useEffect(() => {
        if(product.id) {
            isWishlistProductAPI(product.id).then((res) => {
                if (res) {
                    setIsWish(res.status);
                }
            });
        }
    }, []); // Deja el array de dependencias vacío para que se ejecute solo una vez
    

    return (
        <div
            key={i}
            className="ProductButton"
        >
            {isWish && (
                <div className='WishlistProduct'>
                    <BookmarkIcon height="20px" width="20px" color={"#B33553"} fill={"#EBC7D0"} />
                </div>
            )}
            <ImageComponent  src={product.content_file} width={size} height={size}/>
        </div>
       
    );
}
