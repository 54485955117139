import React from 'react';

function SoundIcon({ width = "32px", height = "32px", color = "#696969" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
      <path d="M14.6667 6.66663L8.00008 12H2.66675V20H8.00008L14.6667 25.3333V6.66663Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25.4266 6.57336C27.9263 9.07373 29.3305 12.4645 29.3305 16C29.3305 19.5356 27.9263 22.9263 25.4266 25.4267M20.72 11.28C21.9698 12.5302 22.6719 14.2256 22.6719 15.9934C22.6719 17.7611 21.9698 19.4565 20.72 20.7067" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default SoundIcon;
