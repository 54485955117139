import React from 'react';

function BookmarkIcon({ color, width, height,fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "32px"}
      height={height || "32px"}
      viewBox="0 0 32 32"
      fill={fill || "none"}
      className="custom-icon"
    >
      <path
        d="M25.3334 28L16.0001 21.3333L6.66675 28V6.66667C6.66675 5.95942 6.9477 5.28115 7.4478 4.78105C7.94789 4.28095 8.62617 4 9.33341 4H22.6667C23.374 4 24.0523 4.28095 24.5524 4.78105C25.0525 5.28115 25.3334 5.95942 25.3334 6.66667V28Z"
        stroke={color || "#696969"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BookmarkIcon;