import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { DeletePostAPI } from "../../API";
// Icons
import TrashIcon from "../../assets/SVG/TrashIcon";
import CloseIcon from '../../assets/SVG/CloseIcon';

function WishlistModal(props) {
    const id = props.post_id;
    const username = props.me;
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const  handleDelete = () => {
       DeletePostAPI(id).then((res) => navigate("/@"+username))
    }





    return (
        <>
            <button className="FullPostHeaderButton" onClick={() => setShowModal(true) }>
               <TrashIcon width='20px' height='20px' color='#696969' />
            </button>
            {showModal &&
            <div
                className='ModalCenteredLayout'
                onClick={() => setShowModal(false)}
            >
                <div
                  className='ModalCenteredBox'
                  onClick={(e) => e.stopPropagation()}
                >
                    <button className='ModalCloseButton' onClick={() => setShowModal(false) }>
                    <CloseIcon width='20px' height='20px' />
                    </button>
                    <div className='ModalTitle'>Eliminar Post</div>
                    <div className='ModalBody'>Confirma que quieres eliminar esta publicación
                    </div>
                <div className='ButtonRow'>
                    <button className='ModalButton' 
                    onClick={handleDelete}
                    > Eliminar </button>
                    <button className="ModalSecondaryButton" onClick={() => setShowModal(false)} > Volver </button>
                </div>
                 
                </div>
              </div>
            }
        </>
    );
}

export default WishlistModal;
