import React from 'react';

const TrashIcon = ({ width = "32px", height = "32px", color = "white" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
      <path d="M4 8H6.66667H28" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25.3334 7.99996V26.6666C25.3334 27.3739 25.0525 28.0521 24.5524 28.5522C24.0523 29.0523 23.374 29.3333 22.6667 29.3333H9.33341C8.62617 29.3333 7.94789 29.0523 7.4478 28.5522C6.9477 28.0521 6.66675 27.3739 6.66675 26.6666V7.99996M10.6667 7.99996V5.33329C10.6667 4.62605 10.9477 3.94777 11.4478 3.44767C11.9479 2.94758 12.6262 2.66663 13.3334 2.66663H18.6667C19.374 2.66663 20.0523 2.94758 20.5524 3.44767C21.0525 3.94777 21.3334 4.62605 21.3334 5.33329V7.99996" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.3333 14.6666V22.6666" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.6667 14.6666V22.6666" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default TrashIcon;
