import React, { useEffect, useState } from "react";
import { LoginAPI } from "../../API";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

//Import Icons

import UserIcon from "../../assets/SVG/UserIcon"
import ShowIcon from "../../assets/SVG/ShowIcon";
import WarningIcon from "../../assets/SVG/WarningIcon";
import LockIcon from "../../assets/SVG/LockIcon";
import CBCLogo from  "../../assets/SVG/CBCLogo";

export default function Login() {
  const invalid_email = "Formato mail inválido";
  const log_in_message = "Ingresar ";
  const forgot_password = "Olvidé mi contraseña";
  const register = " Registrate aquí";
  const no_acount = "No tienes cuenta";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showEmailMessage, setEmailMessage] = useState(null);
  const [showErrorMessage, setErrorMessage] = useState(null);
  const [passswordError,setPasswordError] = useState(null);

  const navigate = useNavigate();

  function isEmail(value) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(value)) {
      setEmailMessage(invalid_email);
    } else {
      setEmailMessage(null);
    }
  }


  useEffect(() => {
    setErrorMessage(null)
    setPasswordError(null)
  }, [email,password]);



  return (
    <div className="PrincipalBox">
      <CBCLogo width="115" height="115" color="white" />
      <Form className="LoginForm" 
            onSubmit={(e) => {
              e.preventDefault();
              if(!showEmailMessage && email && password){
                LoginAPI(email, password).then(res => {
                  if(res.status){
                    window.location.href= "/"
                  }
                  else{
                    setErrorMessage(res.message)
                  }
                });
              }
              if(!email){
                setEmailMessage("Ingrese Email")
              }
              if(!password){
                setPasswordError("Ingrese Contraseña ")
              }
            }}>
        {(showEmailMessage || showErrorMessage || passswordError) && 
        <div  className="ErrorText">
          <WarningIcon /> 
          <div style={{width:"80%"}}> {showEmailMessage}{showErrorMessage} {passswordError} </div>
        </div>}
         
      
        <div className={showEmailMessage || showErrorMessage ?"LoginInput ErrorBorder" :"LoginInput"}>
          <UserIcon width="28px" height="28px" />
          <input
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value.trim().toLocaleLowerCase());
              isEmail(e.target.value.toLocaleLowerCase());
            }}
          />
        </div>

        <div className={showErrorMessage || passswordError ?"LoginInput ErrorBorder" :"LoginInput"}>
          <LockIcon width="28px" height="28px" />
          <input
            type={!showPassword ? "password" : "text"}
            placeholder="Contraseña"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <button
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            <ShowIcon show={!showPassword} width="20px" height="20px" />
          </button>
        </div>

        <button className="RecentPostButton" type="Submit">
          {log_in_message}
        </button>
        <button
            className="LinkButtonDecoration"
            onClick={() => navigate("/resetpassword")}
          >
            {forgot_password}
          </button>
        <div>

  
        
      </div>
      <div className="LoginVariantButton">
          ¿Aún no tienes tu cuenta CranberryChic? 
          <button className="LinkButtonDecoration" onClick={() => navigate("/register")}>
          Crea tu cuenta aquí
          </button>
      </div>
    </Form>
    </div>
  );
}

