import React from 'react';

const ClassesIcon = ({ width = "24px", color = "#000", height = "24px" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 5H3C2.44772 5 2 5.44772 2 6V19C2 19.5523 2.44772 20 3 20H21C21.5523 20 22 19.5523 22 19V6C22 5.44772 21.5523 5 21 5ZM20 18H4V7H20V18ZM6 9H18V11H6V9ZM6 13H16V15H6V13Z"
        fill={color}
      />
    </svg>
  );
};

export default ClassesIcon;
