import React from 'react';

const ClipIcon = ({ width = "32px", height = "32px", color = "#696969" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
      <path d="M14 16.9854C14.4315 17.5622 14.982 18.0395 15.6141 18.3849C16.2463 18.7303 16.9454 18.9357 17.6639 18.9872C18.3824 19.0386 19.1036 18.935 19.7785 18.6832C20.4535 18.4314 21.0664 18.0374 21.5756 17.5279L24.5898 14.5137C25.5049 13.5662 26.0113 12.2972 25.9998 10.98C25.9884 9.66285 25.46 8.40284 24.5286 7.47141C23.5972 6.53997 22.3372 6.01164 21.02 6.00019C19.7028 5.98874 18.4339 6.4951 17.4864 7.41021L15.7583 9.12832" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18 15.0146C17.5685 14.4378 17.018 13.9605 16.3859 13.6151C15.7537 13.2697 15.0546 13.0643 14.3361 13.0128C13.6176 12.9614 12.8964 13.065 12.2215 13.3168C11.5465 13.5686 10.9337 13.9626 10.4244 14.4721L7.41019 17.4863C6.4951 18.4338 5.98874 19.7028 6.00019 21.02C6.01164 22.3372 6.53997 23.5972 7.47139 24.5286C8.40281 25.46 9.6628 25.9884 10.98 25.9998C12.2972 26.0113 13.5661 25.5049 14.5136 24.5898L16.2317 22.8717" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ClipIcon;
