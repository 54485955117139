import React from 'react';

const StarIcon = ({ filled, color = "#696969", width = "16px", height = "17px", variant = "" }) => {
  let strokeColor = "#696969" ;
  let fillColor = filled ? "#696969":"none";
  if (variant === "red") {
    strokeColor = "#B33553"; // Red stroke color
    fillColor = filled ? "#EBC7D0" : "none"; // Red fill color
  } else if (variant === "golden") {
    strokeColor = "#9B896F"; // Golden stroke color
    fillColor = filled ? "#E5DED3" : "none"; // Golden fill color
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill={fillColor}>
      <path d="M7.99992 2.16699L10.0599 6.34033L14.6666 7.01366L11.3333 10.2603L12.1199 14.847L7.99992 12.6803L3.87992 14.847L4.66659 10.2603L1.33325 7.01366L5.93992 6.34033L7.99992 2.16699Z" stroke={strokeColor} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default StarIcon;