import React, { useEffect, useState } from "react";
import { isFollowingAPI, unfollowAPI, followAPI,MeAPI } from "../../API";

import ModalLogin from "./ModalLogin"; 


function FollowButton(props) {
    const [isFollowing, setIsFollowing] = useState(null);
    const user_id = props.user_id;
    const [me,setMe] = useState();
    const [showLogin,setShowLogin] = useState(false);

    useEffect(() => {
      if (!me) {
          const me_ =  JSON.parse(localStorage.getItem("me"));
          setMe(me_);
      }  
    }, [me]);
    
    useEffect(() => {
      if(isFollowing == null && user_id){
        isFollowingAPI(user_id).then((res) => {
          if (res) {
            setIsFollowing(res.status);
          }
        })
      }
  }, [user_id]);


    

    function handlePressFollow(follow) {
        if (follow && me) {
          followAPI(user_id).then((res) => setIsFollowing(res));
        } else if (!follow && me) {
          unfollowAPI(user_id).then((res) => setIsFollowing(res));
        }
        else {
          setShowLogin(true)
        }
      }

    if (me?.id === user_id) {
      return (
        // <button className="FullPostHeaderFollowButton">
        //   Editar Post
        // </button>
        <div />
      );
    } else if (isFollowing) {
      return (
        <button className="FullPostHeaderFollowButton" onClick={() => handlePressFollow(false)}>
          Siguiendo
        </button>
      );
    } else if (!isFollowing) {
      return (
        <>
        <button className="FullPostHeaderFollowButton FullPostHeaderFollowButtonVariant" onClick={() => handlePressFollow(true)}>
          Seguir
        </button>
        {showLogin && <ModalLogin setShow={setShowLogin} />}
        </>
        
      );
    }
  }


  export default FollowButton

