import React from 'react';

const ViajeIcon = ({ width = "24px", height = "24px", color = "#C7506C" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path d="M14.0659 9.79056L15.7641 6.39317L19.0989 5.15057C19.2749 5.08189 19.4354 4.97878 19.5711 4.84729C19.7067 4.71579 19.8148 4.55855 19.8889 4.38477C19.963 4.21099 20.0017 4.02417 20.0027 3.83525C20.0037 3.64633 19.967 3.45911 19.8947 3.28456C19.8225 3.11 19.7161 2.95162 19.5819 2.81869C19.4476 2.68576 19.2882 2.58096 19.1129 2.51042C18.9377 2.43988 18.7501 2.40502 18.5612 2.40788C18.3723 2.41074 18.1859 2.45127 18.0128 2.52709L8.3111 6.74796L6.36154 5.77317C6.30553 5.74556 6.24423 5.73033 6.18181 5.72853C6.11938 5.72673 6.05731 5.7384 5.9998 5.76274L4.28676 6.49491C4.22151 6.52296 4.16413 6.56657 4.11962 6.62192C4.07512 6.67726 4.04485 6.74267 4.03147 6.81242C4.01809 6.88217 4.02199 6.95414 4.04285 7.02203C4.0637 7.08992 4.10087 7.15166 4.1511 7.20187L6.68849 9.73926C6.74861 9.79943 6.82506 9.84065 6.90837 9.8578C6.99168 9.87496 7.07819 9.8673 7.15719 9.83578L12.2154 7.8123L11.4781 9.79056" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.8221 3.91297L10.2613 2.03297C10.1549 1.98901 10.0354 1.98901 9.92908 2.03297L8.44125 2.64949C8.36178 2.68239 8.29384 2.73811 8.24604 2.80962C8.19824 2.88114 8.17273 2.96522 8.17273 3.05123C8.17273 3.13725 8.19824 3.22133 8.24604 3.29284C8.29384 3.36435 8.36178 3.42008 8.44125 3.45297L12.2613 5.03123" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.86963 12H21.1305" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.3044 22H9.82617" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.5653 22V18.9565" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.5742 15.478C14.6379 15.4784 14.7008 15.4925 14.7585 15.5194C14.8163 15.5463 14.8676 15.5854 14.9089 15.6339C14.9502 15.6825 14.9805 15.7393 14.9977 15.8007C15.015 15.862 15.0188 15.9264 15.0089 15.9893C14.8869 16.8147 14.4723 17.5688 13.8406 18.1139C13.209 18.659 12.4024 18.959 11.5681 18.959C10.7337 18.959 9.92715 18.659 9.2955 18.1139C8.66386 17.5688 8.24921 16.8147 8.1272 15.9893C8.11737 15.9268 8.12106 15.8629 8.13802 15.8019C8.15499 15.7409 8.18484 15.6843 8.22555 15.6359C8.26626 15.5874 8.3169 15.5482 8.37403 15.521C8.43117 15.4938 8.49348 15.4791 8.55677 15.478H14.5742Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.6088 17.1628C14.96 17.2535 15.3307 17.2321 15.6691 17.1016C16.0075 16.9712 16.2967 16.7382 16.4961 16.4353C16.6956 16.1323 16.7953 15.7747 16.7814 15.4122C16.7675 15.0498 16.6407 14.7008 16.4186 14.4141C16.1966 14.1273 15.8904 13.9171 15.543 13.813C15.1956 13.7088 14.8243 13.7159 14.4811 13.8332C14.1379 13.9505 13.84 14.1722 13.629 14.4672C13.418 14.7622 13.3045 15.1158 13.3044 15.4784" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default ViajeIcon;
